// USA
export const locale = {
	lang: 'en',
	data: {
		app: {
			dashboard: {
				titles: {
					products: 'Products',
					workflows: 'Workflows',
					members: 'Members',
					tracedByConsumer: 'Traced by consumer',
					workflowStatus: 'Workflow status',
					pendingTasks: 'Pending tasks',
				},
				buttons: {
					completeReport: 'View complete report',
				},
			},
			'account-settings': {
				title: 'Account Settings',
				'organization-settings': {
					logo: 'Company Logo:',
					phone: 'Phone #:',
					company: 'Company:',
					website: 'Website:',
					address: 'Address:',
					city: 'City:',
					state: 'State:',
					country: 'Country:',
					zip: 'ZIP Code:',
					errors: {
						logo: '',
						phone: '',
						company: '',
						website: '',
						address: '',
						city: '',
						state: '',
						country: '',
						zip: '',
					},
				},
				'individual-settings': {
					name: 'Name:',
					lastname: 'Last name:',
					email: 'E-mail:',
					phone: 'Phone #:',
					dob: 'Date of Birth:',
					gender: 'Gender:',
					city: 'City:',
					state: 'State:',
					address: 'Address:',
					address2: 'Address details:',
					country: 'Country:',
					zip: 'ZIP Code:',
					errors: {
						name: '',
						lastname: '',
						email: '',
						phone: '',
						dob: '',
						gender: '',
						city: '',
						state: '',
						address: '',
						address2: '',
						country: '',
						zip: '',
					},
				},
				'general-settings': {
					'default-language': 'Default Language:',
					'enable-error-fixing': 'Enable error fixing:',
					'enable-tracking-color-change': 'Enable tracking color change:',
					'enable-nested-workflow:': 'Enable nested workflows:',
					'xapi-key': 'xAPI Key:',
					smtp: 'SMTP:',
					sandbox: 'Sandbox:',
					'test-email': 'Test E-mail:',
					'add-to-members-inbox': 'Add to members inbox:',
					errors: {
						'default-language': '',
						'enable-error-fixing': '',
						'xapi-key': '',
						smtp: '',
						sandbox: '',
						'test-email': '',
						'add-to-members-inbox': '',
					},
				},
				reports: {
					logo: 'Logo:',
					'browser-tag': 'Browser Tag:',
					'report-language-list': 'Report language list:',
					'report-searchform-content': 'Report searchform content:',
					'search-button-color': 'Search button color:',
					'page-not-found-content': 'Page not found content:',
					errors: {
						logo: '',
						'browser-tag': '',
						'report-language-list': '',
						'report-searchform-content': '',
						'search-button-color': '',
						'page-not-found-content': '',
					},
				},
			},
			tracking: {
				'tracking-details': {
					'more-details-btn': 'More details',
					show_evidence: 'Evidence',
				},
			},
			products: {
				title: 'Products',
				detailsTitle: 'Product Info',
				settingsTitle: 'Product Settings',
				batchesTitle: 'Product Batches',
				activitiesTitle: 'Product Activity',
				grid: {
					title: 'Products listed',
					newBtn: 'New Product',
					columns: {
						product: 'Product',
						ean: 'EAN',
						qr: 'QR',
						company: 'Company',
						status: 'Status',
						actions: 'Actions',
					},
					searchFields: {
						productName: 'Product Name',
						productCategory: 'Product Category',
						ean: 'EAN',
					},
				},
				fields: {
					name: {
						label: 'Product name',
						description: 'The product name is required and recommended to be unique.',
					},
					company: {
						label: 'Company',
						description: 'The company that the product belongs to is required.',
					},
					ean: {
						label: 'EAN',
						description: 'The product EAN code (barcode).',
					},
					description: {
						label: 'Product description',
						description: 'The product description is recommended, as it will appear at consumers app.',
					},
					category: {
						label: 'Category',
						description: 'The product category is required.',
					},
					color: {
						label: 'Color',
						description: 'Product color.',
					},
					externalId: {
						label: 'External Id',
						description: 'External Id.',
					},
					width: {
						label: 'Width',
						description: 'Product width.',
					},
					weight: {
						label: 'weight',
						description: 'Product weight.',
					},
					diameter: {
						label: 'diameter',
						description: 'Product diameter.',
					},
					height: {
						label: 'Height',
						description: 'Product height.',
					},
					length: {
						label: 'Length',
						description: 'Product length.',
					},
					status: {
						label: 'Status',
						description: 'Set the product status. If a product is deactivated, itself of the batches of it  will no longer follow any workflow..',
						options: {
							active: 'Active',
							inactive: 'Inactive',
						},
					},
					qrType: {
						label: 'QR type',
						description: 'Set the QR type. A dynamic QR is used for ...An static QR is used when....',
						options: {
							dynamic: 'Dynamic',
							static: 'Static',
						},
					},
					qrDensity: {
						label: 'QR density',
						description: 'Set the QR density. A high density code will help scan easly the product for workflow members and consumers',
						options: {
							high: 'High',
							low: 'Low',
						},
					},
				},
			},
			productCategories: {
				title: 'Categories',
				grid: {
					title: 'Categories listed',
					newBtn: 'New Category',
					columns: {
						name: 'Name',
						company: 'Company',
						description: 'Description',
						actions: 'Actions',
					},
					searchFields: {
						companyName: 'Company Name',
						categoryName: 'Category Name',
					},
				},
				fields: {
					name: {
						label: 'Name',
						description: 'The category name is required and recommended to be unique.',
						placeholder: 'Category name',
					},
					description: {
						label: 'Description',
						description: 'The description is required.',
						placeholder: 'Category description',
					},
					company: {
						label: 'Company',
						description: 'The company that the product belongs to is required.',
						placeholder: 'Pick a company',
					},
				},
			},
			companies: {
				title: 'Companies',
				detailsTitle: 'Company Info',
				companyImages: 'Company Images',
				marketing: 'Marketing media',
				webapp: 'Webapp',
				grid: {
					title: 'Companies listed',
					newBtn: 'New Company',
					columns: {
						name: 'Name',
						description: 'Description',
						website: 'Website',
						actions: 'Actions',
					},
					searchFields: {
						companyName: 'Filter by company name',
					},
				},
				upload_image: 'Select company image',
				add_other_image: 'Add media',
				fields: {
					companyName: {
						label: 'Company Name',
						description: 'The company name is required and recommended to be unique.',
					},
					companyDescription: {
						label: 'Company Description',
						description: 'The company description is recommended, as it will appear at consumers app.',
					},
					website: {
						label: 'Company website',
						description: 'The company website is recommended, as it will appear at consumers app.',
					},
					instagram: {
						label: 'Instagram account',
						description: 'The company instagram is recommended, as it will appear at consumers app.',
					},
					facebook: {
						label: 'Facebook account',
						description: 'The company facebook is recommended, as it will appear at consumers app.',
					},
					linkedin: {
						label: 'Linkedin account',
						description: 'The company linkedin is recommended, as it will appear at consumers app.',
					},
					stelvioId: {
						label: 'Stelviotech ID',
						description: 'Company ID in this platform.',
					},
					subdomain: {
						label: 'Company subdomain',
						description: 'Company subdomain.',
					},
					externalid: {
						label: 'External ID',
						description: 'Company ID in this platform.',
					},
					media: {
						image_title: {
							label: 'Title',
							description: 'The title is recommended',
						},
						select_media: 'Select media',
						tag: {
							label: 'Media tag',
							description: 'This tag will be user to reference company media',
						},
						text: {
							label: 'Text',
							description: 'Write a company text',
						},
						video: {
							label: 'Video URL',
							description: 'Set a video URL for the company media',
						},
					},
				},
			},
			facilities: {
				title: 'Facilities',
				detailsTitle: 'Facility Info',
				grid: {
					title: 'Facilities listed',
					newBtn: 'New Facility',
					columns: {
						name: 'Name',
						company: 'Company',
						address: 'Address',
						zip: 'zip',
						actions: 'Actions',
					},
					searchFields: {
						companyName: 'Filter by company name',
						address: 'Filter by company address',
					},
				},
				fields: {
					name: {
						label: 'Name',
						description: 'The facility name is required.',
						placeholder: '',
					},
					company: {
						label: 'Company',
						description: 'The company that the product belongs to is required.',
						placeholder: '',
					},
					address: {
						label: 'Address',
						description: 'Facility address is required and recommended to be unique.',
						placeholder: 'Facility address',
					},
					country: {
						label: 'Country',
						description: 'Country is mandatory.',
						placeholder: '',
					},
					zip: {
						label: 'ZIP',
						description: 'Facility ZIP code is required.',
						placeholder: 'ZIP Code',
					},
					latitude: {
						label: 'Latitude',
						description: 'Facility latitude.',
						placeholder: '',
					},
					externalId: {
						label: 'External Id',
						description: 'External Id.',
						placeholder: '',
					},
					longitude: {
						label: 'Longitude',
						description: 'Facility longitude.',
						placeholder: '',
					},
					facilityType: {
						label: 'Facility Type',
						description: 'Type of the facility.',
						placeholder: 'Select facility type',
						options: {
							CompanyFacility: 'Company Facility',
							Farm: 'Farm',
							Customer: 'Customer',
						},
					},
				},
			},
			members: {
				title: 'Members',
				detailsTitle: 'Member Info',
				grid: {
					title: 'Members listed',
					newBtn: 'New Member',
					columns: {
						member: 'Member',
						company: 'Company',
						role: 'Role',
						inProcess: 'In Process',
						enabled: 'Enabled',
						contact: 'Contact',
						actions: 'Actions',
					},
					searchFields: {
						companyName: 'Filter by company name',
						firstName: 'Filter by first name',
						lastName: 'Filter by last name',
						email: 'Filter by email',
					},
				},
				fields: {
					firstName: {
						label: 'Member first name',
						description: 'The member first name is required. Member can’t change it.',
						placeholder: 'John',
					},
					lastName: {
						label: 'Member last name',
						description: 'The member last name is required. Member can’t change it.',
						placeholder: 'Doe',
					},
					company: {
						label: 'Company',
						description: 'The company that member belongs to is mandatory.',
						placeholder: 'Pick a company',
					},
					facility: {
						label: 'Facility',
						details: 'The facility the user mostly works at.',
						placeholder: 'Pick a facility',
						description: 'The facility the user mostly works at.',
					},
					userRole: {
						label: 'User role',
						description: 'The role that member belongs to is mandatory.',
						placeholder: 'Pick a user role',
					},
					password: {
						label: 'Password',
						description: 'Password is required.',
						placeholder: 'abc123',
					},
					passwordConfirmation: {
						label: 'Confirm Password',
						description: 'Password confirmation is required.',
						placeholder: 'abc123',
					},
					gender: {
						label: 'Gender',
						description: 'Member gender.',
						placeholder: 'Pick a gender',
					},
					dob: {
						label: 'Birth date',
						description: "Member's birth date.",
						placeholder: '00/00/0000',
					},
					status: {
						label: 'Status',
						options: {
							enabled: 'Enabled',
							disabled: 'Disabled',
						},
						description: '',
						placeholder: '',
					},
					address: {
						label: 'Address',
						description: "Member's address.",
						placeholder: 'Street 123',
					},
					email: {
						label: 'Contact email',
						description: 'Only the member can change the email.',
						placeholder: 'your@mail.net',
					},
					phone: {
						label: 'Contact phone',
						description: 'Only the member can change the phone number.',
						placeholder: '1234567890',
					},
					username: {
						label: 'Username',
						description: 'Username to login',
						placeholder: 'Username',
					},
				},
			},
			workflows: {
				title: 'Workflows',
				detailsTitle: 'Workflow Info',
				settingsTitle: 'Workflow Settings',
				grid: {
					title: 'Workflows listed',
					newBtn: 'New Workflow',
					columns: {
						name: 'Workflow Name',
						steps: 'Steps',
						description: 'Description',
						status: 'Status',
						actions: 'Actions',
					},
					searchFields: {
						companyName: 'Filter by company name',
						name: 'Filter by workflow name',
						description: 'Filter by workflow description',
					},
				},
				fields: {
					name: {
						label: 'Workflow name',
						description: 'The workflow name is required and recommended to be unique.',
						placeholder: 'Workflow name',
					},
					description: {
						label: 'Workflow description',
						description: 'The workflow description is required.',
						placeholder: 'Workflow description',
					},
					product: {
						label: "Workflow's Product",
						description: "Select a workflow's products.",
						placeholder: 'Pick products',
					},
					stelvioId: {
						label: 'Stelviotech ID',
						description: 'Product ID in this platform.',
						placeholder: '',
					},
					status: {
						label: 'Status',
						description: 'Set the workflow status. If a workflow is deactivated, batches will stop the process. For sandbox info, click here.',
						placeholder: '',
						options: {
							active: 'Active',
							inactive: 'Inactive',
						},
					},
					type: {
						label: 'Workflow type',
						description: 'A “continued workflow” is chained to other workflow that continues the processing of a batch.',
						placeholder: '',
						options: {
							regular: 'Regular',
							continued: 'Continued',
						},
					},
					bioIndicator: {
						label: 'Enable BIO indicator',
						description: 'Enable if it is allowed scan Bio indicator',
						placeholder: '',
						options: {
							enable: 'Enable',
							disable: 'Disable',
						},
					},
					automatic: {
						label: 'Automatic data',
						description: 'Enable if you will receive automatic data',
						placeholder: '',
						options: {
							enable: 'Enable',
							disable: 'Disable',
						},
					},
					nextWorkflow: {
						label: 'Next Workflow',
						description: 'Following workflow must be selected por continued workflows.',
						placeholder: 'Select following workflow',
						btnNew: 'New Workflow',
					},
				},
				steps: {
					title: 'Workflow Steps',
					newBtn: 'New Step',
					errors: {
						noSteps: 'No steps have been added to this workflow yet.',
					},
					assigned: {
						title: 'Steps assigned',
					},
					modal: {
						title: 'Step Info',
						fieldsTitle: 'Form fields for this step',
						newBtn: 'New field',
						fields: {
							name: {
								label: 'Step name',
								description: 'The step name is required and recommended to be unique.',
								placeholder: 'Step name',
							},
							description: {
								label: 'Description',
								description: 'The step description is required.',
								placeholder: 'Step description',
							},
							responsibles: {
								label: 'Responsible(s)',
								description: 'Choose members that will be responsible for this task.',
								placeholder: 'Choose responsible(s)',
							},
							mandatory: {
								label: 'Mandatory',
								description: '',
								placeholder: '',
								options: {
									mandatory: 'Mandatory',
									optional: 'Optional',
								},
							},
						},
						stepField: {
							deleteBtn: 'Delete Field',
							fieldTypes: {
								TEXT: 'Text',
								NUMBER: 'Number',
								DATE: 'Date',
								TIMESTAMP: 'Time',
								SELECT: 'Select',
								LOCATION: 'Location',
								DOCUMENT: 'Document',
								IMAGE: 'Image',
								UNIT: 'Units',
							},
							fields: {
								name: {
									label: 'Field name',
									description: '',
									placeholder: 'Field name',
								},
								description: {
									label: 'Field description',
									description: '',
									placeholder: 'Field description',
								},
								type: {
									label: 'Field type',
									description: '',
									placeholder: 'Field type',
								},
							},
							dynamicSelect: {
								title: 'Options',
								label: 'Label',
								value: 'Value',
							},
							options: {
								mandatory: 'Mandatory',
								primary: 'Primary',
								save: 'Save',
								automatic: 'Automatic',
								mapIn: 'Map in',
								mapOut: 'Map out',
							},
						},
					},
				},
				marketing: {
					title: 'Marketing settings',
				},
			},
			sus_frameworks: {
				title: 'Sustainability Frameworks',
				detailsTitle: 'Frameworks Information',
				objectivesTitle: 'Objectives',
				settingsTitle: 'Frameworks Configuration',
				grid: {
					title: 'Sustainability Frameworks',
					newBtn: 'New framework',
					columns: {
						name: 'Framework Name',
						steps: 'Metrics',
						description: 'Description',
						status: 'Status',
						actions: 'Actions',
					},
					searchFields: {
						companyName: 'Filter by company name',
						name: 'Filter by workflow name',
						description: 'Filter by workflow description',
					},
				},
				fields: {
					name: {
						label: 'Framework name',
						description: 'The Framework name is required and recommended to be unique.',
						placeholder: 'Framework name',
					},
					description: {
						label: 'Framework description',
						description: 'The Framework description is required.',
						placeholder: 'Framework description',
					},
					date: {
						label: 'Date',
						description: 'The Framework date is required.',
						placeholder: 'Framework date',
					},
					objectives: {
						label: 'Objectives',
						description: 'The Framework objectives is required.',
						placeholder: 'Framework objectives',
					},
					product: {
						label: "Framework's Product",
						description: "Select a Framework's products.",
						placeholder: 'Pick products',
					},
					stelvioId: {
						label: 'Stelviotech ID',
						description: 'Product ID in this platform.',
						placeholder: '',
					},
					status: {
						label: 'Status',
						description: 'Set the Framework status. If a Framework is deactivated, batches will stop the process. For sandbox info, click here.',
						placeholder: '',
						options: {
							active: 'Active',
							inactive: 'Inactive',
						},
					},
					type: {
						label: 'Framework type',
						description: 'A “continued Framework” is chained to other framework that continues the processing of a batch.',
						placeholder: '',
						options: {
							regular: 'Regular',
							continued: 'Continued',
						},
					},
					bioIndicator: {
						label: 'Enable BIO indicator',
						description: 'Enable if it is allowed scan Bio indicator',
						placeholder: '',
						options: {
							enable: 'Enable',
							disable: 'Disable',
						},
					},
					automatic: {
						label: 'Automatic data',
						description: 'Enable if you will receive automatic data',
						placeholder: '',
						options: {
							enable: 'Enable',
							disable: 'Disable',
						},
					},
					nextWorkflow: {
						label: 'Next Workflow',
						description: 'Following workflow must be selected por continued workflows.',
						placeholder: 'Select following workflow',
						btnNew: 'New Workflow',
					},
				},
				kpis: {
					title: 'Framework KPIs',
					newBtn: 'New KPI',
					errors: {
						noSteps: 'No KPIs have been added to this framework yet.',
					},
					assigned: {
						title: 'KPIs assigned',
					},
					modal: {
						title: 'KPI Info',
						fieldsTitle: 'Form fields for this step',
						newBtn: 'New field',
						fields: {
							name: {
								label: 'KPI name',
								description: 'The KPI name is required and recommended to be unique.',
								placeholder: 'KPI name',
							},
							subcategory: {
								label: 'Subcategory',
								description: 'The KPI subcategory is required.',
								placeholder: 'KPI subcategory',
							},
							description: {
								label: 'Description',
								description: 'The KPI description is required.',
								placeholder: 'KPI description',
							},
							value: {
								label: 'Value',
								description: 'Introduce a value',
								placeholder: '',
							},
							measure: {
								label: 'Measure',
								description: 'Select a measure',
								placeholder: '',
							},
						},
						stepField: {
							deleteBtn: 'Delete Field',
							fieldTypes: {
								TEXT: 'Text',
								NUMBER: 'Number',
								DATE: 'Date',
								TIMESTAMP: 'Time',
								SELECT: 'Select',
								LOCATION: 'Location',
								DOCUMENT: 'Document',
								IMAGE: 'Image',
								UNIT: 'Units',
							},
							fields: {
								name: {
									label: 'Field name',
									description: '',
									placeholder: 'Field name',
								},
								description: {
									label: 'Field description',
									description: '',
									placeholder: 'Field description',
								},
								type: {
									label: 'Field type',
									description: '',
									placeholder: 'Field type',
								},
							},
							dynamicSelect: {
								title: 'Options',
								label: 'Label',
								value: 'Value',
							},
							options: {
								mandatory: 'Mandatory',
								primary: 'Primary',
								save: 'Save',
								automatic: 'Automatic',
								mapIn: 'Map in',
								mapOut: 'Map out',
							},
						},
					},
				},
				marketing: {
					title: 'Marketing settings',
				},
			},
			reports: {
				title: 'Sustainability Reporting',
				detailsTitle: 'Reports Information',
				objectivesTitle: 'Objectives',
				settingsTitle: 'Reports Configuration',
				grid: {
					title: 'Sustainability Reporting',
					newBtn: 'New Report',
					columns: {
						name: 'Report Name',
						chapters: 'Chapters',
						metrics: 'Metrics',
						date: 'Date',
						description: 'Description',
						objectives: 'Objectives',
						version: 'Version',
						frameworks: 'Frameworks',
						status: 'Status',
						actions: 'Actions',
					},
					searchFields: {
						companyName: 'Filter by company name',
						name: 'Filter by workflow name',
						description: 'Filter by workflow description',
					},
				},
				fields: {
					name: {
						label: 'Report name',
						description: 'The Report name is required and recommended to be unique.',
						placeholder: 'Report name',
					},
					chapters: {
						label: 'Chapters',
						description: 'The Chapter  is required.',
						placeholder: 'Chapter',
					},
					version: {
						label: 'Version',
						description: 'The Version is required.',
						placeholder: 'Version',
					},
					description: {
						label: 'Report description',
						description: 'The Report description is required.',
						placeholder: 'Report description',
					},
					date: {
						label: 'Date',
						description: 'The Report date is required.',
						placeholder: 'Report date',
					},
					objectives: {
						label: 'Objectives',
						description: 'The Report objectives is required.',
						placeholder: 'Report objectives',
					},
					status: {
						label: 'Status',
						description: 'Set the Framework status. If a Framework is deactivated, batches will stop the process. For sandbox info, click here.',
						placeholder: '',
						options: {
							active: 'Active',
							inactive: 'Inactive',
						},
					},
				},
				kpis: {
					title: 'Report Chapters',
					newBtn: 'New Chapter',
					errors: {
						noSteps: 'No KPIs have been added to this framework yet.',
					},
					assigned: {
						title: 'KPIs assigned',
					},
					modal: {
						title: 'KPI Info',
						fieldsTitle: 'Form fields for this step',
						newBtn: 'New field',
						fields: {
							name: {
								label: 'KPI name',
								description: 'The KPI name is required and recommended to be unique.',
								placeholder: 'KPI name',
							},
							subcategory: {
								label: 'Subcategory',
								description: 'The KPI subcategory is required.',
								placeholder: 'KPI subcategory',
							},
							description: {
								label: 'Description',
								description: 'The KPI description is required.',
								placeholder: 'KPI description',
							},
							value: {
								label: 'Value',
								description: 'Introduce a value',
								placeholder: '',
							},
							measure: {
								label: 'Measure',
								description: 'Select a measure',
								placeholder: '',
							},
						},
						stepField: {
							deleteBtn: 'Delete Field',
							fieldTypes: {
								TEXT: 'Text',
								NUMBER: 'Number',
								DATE: 'Date',
								TIMESTAMP: 'Time',
								SELECT: 'Select',
								LOCATION: 'Location',
								DOCUMENT: 'Document',
								IMAGE: 'Image',
								UNIT: 'Units',
							},
							fields: {
								name: {
									label: 'Field name',
									description: '',
									placeholder: 'Field name',
								},
								description: {
									label: 'Field description',
									description: '',
									placeholder: 'Field description',
								},
								type: {
									label: 'Field type',
									description: '',
									placeholder: 'Field type',
								},
							},
							dynamicSelect: {
								title: 'Options',
								label: 'Label',
								value: 'Value',
							},
							options: {
								mandatory: 'Mandatory',
								primary: 'Primary',
								save: 'Save',
								automatic: 'Automatic',
								mapIn: 'Map in',
								mapOut: 'Map out',
							},
						},
					},
				},
				marketing: {
					title: 'Marketing settings',
				},
			},
			common: {
				forms: {
					comboBoxes: {
						pickCompany: 'Pick a company',
						pickCategory: 'Pick a category',
					},
					errors: {
						checkFields: 'Please check all mandatory fields.',
						unsavedChanges: 'Beware! There are changes unsaved.',
					},
					buttons: {
						applyFilters: 'Apply Filters',
						saveAndClose: 'Save & Close',
						close: 'Close',
						cancel: 'Cancel',
					},
				},
				buttons: {
					edit: 'Edit',
					view: 'View',
					viewOrEdit: 'View/Edit',
					remove: 'Remove',
					active: 'Active',
					inactive: 'Inactive',
					search: 'Search',
					viewAll: 'View all',
					duplicate: 'Duplicate',
				},
				errors: {},
			},
			components: {
				notifications: {
					title: 'Notifications',
					reports: '{{qty}} reports',
					noNotifications: 'No new notifications',
					noLogs: 'No new logs',
				},
			},
		},
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			MAIN: 'Main menu',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			PRODUCTS: 'Products',
			CATEGORIES: 'Product Categories',
			WORKFLOWS: 'Workflows',
			FRAMEWORKS: 'Sustainability Frameworks',
			COMPANIES: 'Companies',
			FACILITIES: 'Facilities',
			ACTIVITY: 'Activity',
			MEMBERS: 'Members',
			TRACKING: 'Tracking',
			SETTINGS: 'Settings',
			SUST_PER_BATCH: 'Sus. per batch',
			PROFILE: 'My Profile',
			PROJECTS: 'Projects',
			REGENERATIVE_FINANCE: 'Regenerative Finance',
			MY_ASSETS: 'My assets',
			TEAM: 'Team',
			CONFIGURATION: 'Configuration',
			SUPPLY_CHAIN: 'Supply chain',
			SUSTAINABILITY: 'Sustainability',
			REPORTS: 'Reports',
			KPI_STATS: 'KPI Stats',
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: "Don't have an account?",
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Your account has been successfully reset.',
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.',
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			},
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields',
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted',
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted',
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated',
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created',
				},
			},
		},
	},
}
