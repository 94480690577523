import {Injectable} from "@angular/core";
import {CountriesRequest} from "../requests/countries.request";
import {map} from "rxjs/operators";

@Injectable()
export class CountriesFacade {
    constructor(private request: CountriesRequest) {
    }

    query() {
        return this.request.query().pipe(
            map( (response: any) => response.data)
        );
    }
}