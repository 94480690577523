import { Component, Input } from "@angular/core";

export interface ContactButtonModel {
  link: string;
  tooltip: string;
  class: string;
  label: string;
  icon: string;
}

@Component({
  selector: "stelvio-contact-buttons",
  templateUrl: "./contact-buttons.component.html",
  styleUrls: ["./contact-buttons.component.scss"],
})
export class ContactButtonsComponent {
  @Input() settings: ContactButtonModel[];

  constructor() {}
}
