import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AuthService} from "../../auth";

@Directive({
    selector: '[accessControl]'
})
export class AccessControlDirective implements OnInit {
    @Input('roles') roles: string[];
    @Input('restrictionType') restrictionType: 'hide' | 'disable' = 'hide';
    private displayType: string;

    constructor(private elementRef: ElementRef, private authService: AuthService) {
    }

    ngOnInit() {
        this.displayType = this.elementRef.nativeElement.style.display;
        this.elementRef.nativeElement.style.display = 'none';
        this.checkAccess();
    }

    private checkAccess() {
        const enabledRoles = this.roles.filter(r => {
            return this.authService.userHasRole(r);
        });
        this.fireRestriction(this.authService.isUserLoggedIn && !!enabledRoles.length);
    }

    private fireRestriction(accessGranted: boolean) {
        switch (this.restrictionType) {
            case 'hide':
                this.elementRef.nativeElement.style.display = accessGranted ? this.displayType || 'block' : 'none';
                break;
            case 'disable':
                this.elementRef.nativeElement.disable = !accessGranted;
                break;
        }
    }
}