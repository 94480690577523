import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-total-card',
  templateUrl: './total-card.component.html',
  styleUrls: ['./total-card.component.scss']
})
export class TotalCardComponent implements OnInit {
  @Input('color') color: string;
  @Input('color_text') color_text: string;
  @Input('title') title: string;
  @Input('titlePending') titlePending: string;
  @Input('total') total: number;
  @Input('totalPending') totalPending: number;
  @Input('icon') icon: string;
  @Input('noSubtitle') noSubtitle: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
