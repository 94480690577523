import {Component, Input} from "@angular/core";

@Component({
    selector: 'stelvio-task-overview',
    templateUrl: './task-overview.component.html',
    styleUrls: ['./task-overview.component.scss']
})
export class TaskOverviewComponent {
    @Input() settings: any;

    constructor() {
    }

    fireAction() {
        this.settings.click();
    }
}