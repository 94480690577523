import {Component, Input, OnInit} from '@angular/core';
import {act} from "react-dom/test-utils";

@Component({
  selector: 'app-dashboard-table-card',
  templateUrl: './dashboard-table-card.component.html',
  styleUrls: ['./dashboard-table-card.component.scss']
})
export class DashboardTableCardComponent implements OnInit {
  @Input('lastActivities') lastActivities:any;
  constructor() { }

  ngOnInit(): void {
  }

  protected readonly act = act;
}
