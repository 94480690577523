import {Injectable} from "@angular/core";
import {MembersRequest} from "../requests/members.request";
import {Observable} from "rxjs";
import {MemberModel} from "../models";
import {map} from "rxjs/operators";
import {PaginationModel} from "../../common/models/pagination.model";
import {environment} from "../../../../environments/environment";

@Injectable()
export class MembersFacade {
    private defaultPagination: PaginationModel = {
        last: false,
        pageNumber: 0,
        pageSize: environment.pagination.pageSize,
        totalPages: 0,
        totalElements: 0,
    }
    constructor(private request: MembersRequest) {}

    query(pagination: PaginationModel = this.defaultPagination, filters?: any): Observable<any> {
        return this.request.query(pagination, filters).pipe(
            map( (result: any) => {
                return {
                    ...result,
                    content: result.content.map( (member: any) => this._buildAvatarAndBadges(member))
                }
            })
        );
    }

    get(memberId: string) {
        return this.request.get(memberId).pipe(
            map( (member: any) => this._buildAvatarAndBadges(member))
        );
    }

    create(member: MemberModel) {
        return this.request.create(member);
    }

    update(member: MemberModel) {
        return this.request.update(member);
    }

    delete(memberId: number) {
        return this.request.delete(memberId);
    }

    uploadProfilePicture(image: any, idMember: number, idCompany: number) {
        return this.request.uploadProfilePic(image, idMember, idCompany);
    }

    dashboard(id: number) {
        return this.request.dashboard(id);
    }

    private _buildAvatarAndBadges(member: any) {
        let imagePath = '../../../../assets/media/avatars/generic.png';
        if(!!member?.avatar[0]?.path) {
            imagePath = `/images/${member.company.id}/${member.avatar[0].path}`;
        }
        let companyImagePath: string = ''
        if(member?.company?.logo?.path){
            const companyLogo = member?.company?.logo?.path.split("/")[2]
            companyImagePath = `/images/${member.company.id}/${companyLogo}`;
        }
        return {
            ...member,
            imagePath,
            fullName: member.firstName + ' ' + member.lastName,
            isEnabled: member.enabled ? 'Enabled' : 'Disabled',
            badgeType: member.enabled ? 'active' : 'inactive',
            parsedRoles: member.roles.map( (r: any) => r.description). join(','),
            contactButtons: this._buildContactButons(member),
            companyImagePath
        };
    }

    private _buildContactButons(member: any): any[] {
        const contactBtns = [];
        if(!!member.phone) {
            contactBtns.push({
                link: member.phone,
                tooltip: member.phone,
                class: 'btn-phone',
                label: '',
                icon: 'assets/media/svg/icons/call.svg',
            });
        }
        if(!!member.email) {
            contactBtns.push({
                link: `mailto: ${member.email}`,
                tooltip: member.email,
                class: 'btn-email',
                label: '',
                icon: 'assets/media/svg/icons/mail.svg',
            });
        }
        return contactBtns;
    }
}