import {Injectable} from "@angular/core";
import {MessagesRequest} from "../requests/messages.request";

@Injectable()
export class MessagesFacade {
    constructor(private request: MessagesRequest) {}

    getMessage(messageId: string) {
        return this.request.get(messageId);
    }

    getUserMessages(userId: string){
        return this.request.getUserMessages(userId);
    }
}