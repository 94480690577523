import {Injectable} from "@angular/core";
import {ActivitiesRequest} from "../requests/activities.request";
import {ActivityShortModel} from "../models";
import {BehaviorSubject, Observable} from "rxjs";
import {PaginationModel} from "../models/pagination.model";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable()
export class ActivitiesFacade {
    private defaultPagination: PaginationModel = {
        last: false,
        pageNumber: 0,
        pageSize: environment.pagination.pageSize,
        totalPages: 0,
        totalElements: 0,
    }
    public pages$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private defaultPic: string = '../../../../../assets/media/avatars/generic-product.webp';
    private defaultCompanyPic: string = '../../../../assets/media/avatars/company-generic.png';

    constructor(private request: ActivitiesRequest) {
    }
    query(pagination: PaginationModel = this.defaultPagination,filters?: any) {
        return this.request.query(pagination, filters).pipe(
            map( (result: any) => {
                return {
                    ...result,
                    content: result.content.map( (product: any) => this._buildPicturePath(product))
                    //     .sort((a: any, b: any) => {
                    //     if (a.workflowInstanceId === b.workflowInstanceId) {
                    //         return a.stepOrder - b.stepOrder;
                    //     } else {
                    //         return a.workflowInstanceId - b.workflowInstanceId;
                    //     }
                    // })
                }
            })
        );
    }
    getAll(filters?: any){
        return this.request.getAll(filters).pipe(
            map( (result: any) => {
                console.log(result)
                return {
                    ...result,
                    content: result.map( (product: any) => this._buildPicturePath(product))
                }
            })
        );
    }
    queryWithData(filters?: any) {
        return this.request.queryWithData(filters);
    }

    assignUser(workflowId: number, workflowStepId: number, companyId: number, userIds: number[]) {
        return this.request.assignUser(workflowId,workflowStepId, companyId, userIds);
    }

    getStepUsers(workflowId: number, workflowStepId: number) {
        return this.request.getStepUsers(workflowId, workflowStepId);
    }

    getUserSteps(userId: number) {
        return this.request.getUserSteps(userId);
    }

    removeUser(workflowId: number, workflowStepId: number, companyId: number, userIds: number[]) {
        return this.request.removeUser(workflowId,workflowStepId, companyId, userIds);
    }

    get(id?: any) {
        return this.request.get(id);
    }

    create(activity: ActivityShortModel): Observable<any> {
        return this.request.create(activity);
    }

    update(activity: ActivityShortModel) {
        return this.request.update(activity);
    }

    delete(id: number) {
        return this.request.delete(id);
    }

    uploadImage(image: any) {
        return this.request.uploadImage(image);
    }

    uploadDoc(doc: any) {
        return this.request.uploadDoc(doc);
    }

    existsFinalBatchOut(batchId: string, productId: number) {
        return this.request.existsFinalBatchOut(batchId, productId);
    }

    private _buildPicturePath(activity: any) {
        const companyId =  activity.products[0]?.company?.id?activity.products[0]?.company?.id:activity.companyId
        const imagePath: string = activity.products[0]?.image == null || activity.products[0]?.image.path.includes('null') ? this.defaultPic : `/images/${companyId}/${activity.products[0].image.path}`;
        let companyImagePath: string = this.defaultCompanyPic
        let productName = activity.products[0].title
        if(activity?.company?.logo?.path){
            const companyLogo = activity?.company?.logo?.path
            companyImagePath = `/images/${companyId}/${companyLogo}`;
        }
        return {
            productName,
            ...activity,
            imagePath,
            companyImagePath
        }
    }
}
