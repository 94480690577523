import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {ToastService} from "../../common/services";

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastService: ToastService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const ls = Object.keys(localStorage);
        const key = ls.find( key => key.indexOf('auth') > -1) || '';
        const lsToken = localStorage.getItem(key) || null;
        const token = lsToken !== null ?  JSON.parse(lsToken) : null;

        if(!!token) {
            request = request.clone({
                setHeaders: {Authorization: `Bearer ${token.authToken}`}
            })
        }

        return next.handle(request).pipe(
            catchError((err) => {
                if(err instanceof HttpErrorResponse) {
                    if(err.status === 401 && err && err?.url && err.url.indexOf('protocol/openid-connect/token') == -1) {
                        this.router.navigate(['/auth/logout']);
                    } else if(err.status === 403) {
                       if(!err?.url?.includes('blockchain')){
                           this.toastService.showErrorToast('Access Forbidden', 'You do not have privilege to access the requested resource.')
                           this.router.navigate(['/dashboard/']);
                       }

                    }
                }
                return throwError(err);
            })
        )
    }
}