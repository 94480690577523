import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ControlContainer, FormControl} from "@angular/forms";

@Component({
    selector: 'stelvio-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ToggleComponent implements OnInit {
    control: FormControl;
    constructor(public controlContainer: ControlContainer) {
    }

    ngOnInit() {
        this.control = this.controlContainer.control as FormControl;
    }
}