export interface WorkflowStepDataComboOptionModel {
    key: string;
    value: string;
}

export const WorkflowDataTypes = {
    'TEXT' : 'Text',
    'NUMBER' : 'Number',
    'UNIT' : 'Unit',
    'DATE' : 'Date',
    'TIMESTAMP' : 'Date & Time',
    'SELECT' : 'Select',
    'LOCATION' : 'Location',
    'DOCUMENT' : 'Document',
    'IMAGE' : 'Image'
}

export interface WorkflowStepDataModel {
    id?: number;
    name: string;
    description: string;
    primary?: boolean;
    save?: boolean;
    inbox?: boolean;
    outbox?: boolean;
    mandatory: boolean;
    automatic: boolean;
    options?: WorkflowStepDataComboOptionModel[];
    workflowDataType: 'TEXT' | 'NUMBER' | 'DATE' | 'TIMESTAMP' | 'SELECT' | 'LOCATION' | 'DOCUMENT' | 'IMAGE' | 'UNIT';
}

export interface WorkflowStepModel {
    id?: number;
    name: string;
    description: string;
    mandatory: boolean;
    _public: boolean;
    stepOrder: number;
    workflowStepDataFields: WorkflowStepDataModel[];
}
