<div class="grid-content border">
	<div class="row grid-content__header border-bottom align-items-center m-0">
		<div class="col-1" *ngIf="!!settings.children.length"></div>
		<div class="col py-2 border-end" *ngFor="let column of settings.columns" [ngClass]="{ 'can-sort': column.sortable, hide: column.hidden }" (click)="toggleSort(column)">
			{{ column.label | translate }}
			<i class="fa" [ngClass]="getIconClass(column.field)" *ngIf="column.sortable"></i>
		</div>
	</div>
	<div class="grid-content__body">
		<div class="row align-items-center" *ngFor="let row of data | async; let i = index">
			<div class="col-1" *ngIf="!!settings.children.length">
				<div class="toggler" (click)="toggleRow(row)" [ngClass]="{ open: row.open || settings.toggleRowOpen }"></div>
			</div>
			<div class="col py-2" *ngFor="let column of settings.columns; let iC = index" [ngClass]="{ hide: column.hidden }">
				<div class="grid-conent__body-column-content w-100" [ngClass]="settings.columns[iC].type" [ngSwitch]="settings.columns[iC].type">
					<div *ngSwitchCase="'image'" class="capitalize w-100">
						<div class="row justify-content-end">
							<img width="100" class="item-pic mr-1 text-end" style="object-fit: contain" [src]="row[column.field] || getField(column.avatarField, row) || settings.columns[iC].avatar" />
						</div>
					</div>

					<span *ngSwitchCase="'string'" class="capitalize ellipsis">{{ getField(column.field, row) }}</span>
					<span *ngSwitchCase="'string_small'" title="{{ getField(column.field, row) }}" class="capitalize ellipsis">
						<small>{{ getField(column.field, row) }}</small>
					</span>
					<span *ngSwitchCase="'string_sus'" class="capitalize ellipsis">
						<b>{{ getField(column.field, row) }}</b>
					</span>
					<span *ngSwitchCase="'description'" class="ellipsis">{{ getField(column.field, row) }}</span>
					<span *ngSwitchCase="'stringArray'" class="capitalize">{{ row[column.field].join(',') }}</span>
					<span *ngSwitchCase="'html'" class="contact-html" [innerHTML]="row[column.field]"></span>
					<span *ngSwitchCase="'contact'">
						<stelvio-contact-buttons [settings]="row[column.field]"></stelvio-contact-buttons>
					</span>
					<span *ngSwitchCase="'date'" class="capitalize">{{ getField(column.field, row) | date }}</span>
					<div *ngSwitchCase="'stringAndPic'">
						<div class="row m-0 align-items-center w-100">
							<div class="col-5">
								<div class="p-0 d-flex" style="width: 60px; height: 60px">
									<img class="item-pic mr-1 w-100" style="object-fit: contain" [src]="getField(column.avatarField, row) || settings.columns[iC].avatar" />
								</div>
							</div>
							<div class="col-7">{{ getField(column.field, row) }}</div>
						</div>
					</div>
					<span *ngSwitchCase="'button'">{{ getField(column.field, row) }}</span>
					
					<!-- <div *ngSwitchCase="'image'" class="row justify-content-end">
						<img class="item-pic" [src]="row[column.field]" alt="" />
					</div> -->
				
					<span *ngSwitchCase="'qr'">
						<img style="width: 100px !important" class="item-pic" [src]="row[column.field]" alt="" />
					</span>
					<div *ngSwitchCase="'badge'" class="w-auto d-flex m-auto">
						<span class="badge" [title]="getField(column.field, row)" [ngClass]="getFieldBadgeClass(getField(column.field, row), settings.columns[iC].badgeType)">
							{{ getField(column.field, row) }}
						</span>
					</div>
					<div *ngSwitchCase="'custom-badge'" class="w-auto d-flex m-auto">
						<span class="badge" [title]="getField(column.field, row)" [ngClass]="getFieldBadgeClass(getField(column.field, row), settings.columns[iC].badgeType)">
							{{ getField(column.field, row) }}
						</span>
					</div>

					<div *ngSwitchCase="'badge-array'">
						<div class="w-100 mb-2" *ngFor="let value of getField(column.field, row)">
							<div class="w-auto d-flex">
								<span
									class="badge d-flex text-black"
									[title]="value.name ? value.name : value"
									[ngClass]="getFieldBadgeClass(getField(column.field, row), settings.columns[iC].badgeType)">
									{{ value.name ? value.name : value }}
								</span>
							</div>
						</div>
					</div>
					<div *ngSwitchCase="'avatarName'" class="contact-html">
						<app-responsibles-avatar [data]="getField(column.field, row)"></app-responsibles-avatar>
					</div>

					<span *ngSwitchCase="'actions'">
						<options-menu
							[btnIcon]="'../../../../../../assets/media/icons/duotune/arrows/arr072.svg'"
							[btnLabel]="'Actions'"
							[options]="column.options"
							[disabled]="settings.disabled || false"
							(onAction)="fireAction({ id: row.id, option: $event, item: row })"></options-menu>
					</span>
				</div>
			</div>
			<div class="grid-content__body-row-children" [ngClass]="{ open: row.open || settings.toggleRowOpen }" *ngIf="row.children?.length > 0">
				<div class="grid-content">
					<div class="row grid-content__body-row-children__header p-0 m-0">
						<div class="col" *ngFor="let childColumn of settings.children">
							<b>{{ childColumn.label }}</b>
						</div>
					</div>
					<div class="row p-0" [ngClass]="{ hide: row.hidden }">
						<div class="row m-0" *ngFor="let childRow of row.children">
							<div class="col" *ngFor="let childColumn of settings.children; let iCch = index">
								<div class="grid-conent__body-column-content" [ngSwitch]="childColumn.type">
									<span *ngSwitchCase="'string'">{{ getField(childColumn.field, childRow) }}</span>
									<span *ngSwitchCase="'button'">{{ getField(childColumn.field, childRow) }}</span>
									<span *ngSwitchCase="'image'">
										<img [src]="childRow[childColumn.field]" alt="" />
									</span>
									<span *ngSwitchCase="'imageAndName'">
										<img class="item-pic" [src]="childRow[childColumn.field]" alt="" />
										{{ childRow.name }}
									</span>
									<span *ngSwitchCase="'badge'" class="badge" [title]="childRow[childColumn.field]" [ngClass]="getBadgeClass(childRow.badgeType)">
										{{ childRow[childColumn.field] }}
									</span>
									<span *ngSwitchCase="'actions'">
										<options-menu
											[btnIcon]="'../../../../../../assets/media/icons/duotune/arrows/arr072.svg'"
											[btnLabel]="'Actions'"
											(onAction)="fireAction({ id: row.id, option: $event, item: row })"
											[options]="childColumn.options"></options-menu>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="(data | async)?.length == 0">
			<div class="row justify-content-center">
				<h3 class="text-center p-4" *ngIf="message_no_data">{{ message_no_data }}</h3>
			</div>
		</div>
	</div>
</div>
