import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { WorkflowModel } from '../models'
import { PaginationModel } from '../models/pagination.model'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class SustainabilityFrameworkRequest {
	private apiRoot = `${environment.apiUrl}/sustainability/frameworks`
	private readonly jsonFilePath = 'assets/data/sustainability/framework_data.json'

	constructor(private http: HttpClient) {}

	create(framework: any) {
		return this.http.post(`${this.apiRoot}/`, framework)
	}

	get(id: string): Observable<any> {
		return this.http.get<any>(this.jsonFilePath).pipe(
			map((data) => {
				const frameworks = data.content || []
				return frameworks.find((item: any) => item.id === +id)
			}),
		)
	}

	query(pagination: PaginationModel, params?: any) {
		return this.http.get(this.jsonFilePath)
	}

	private applyFilters(data: any[], params: any): any[] {
		if (!params) {
			return data
		}
		// Filtra los datos según los parámetros
		return data.filter((item) => Object.keys(params).every((key) => item[key] === params[key]))
	}

	private paginate(data: any[], pagination: PaginationModel): any[] {
		const startIndex = pagination.pageNumber * pagination.pageSize
		return data.slice(startIndex, startIndex + pagination.pageSize)
	}

	find(workflowId: string) {
		return this.http.get(`${this.apiRoot}/${workflowId}`)
	}

	update(workflow: WorkflowModel) {
		return this.http.put(`${this.apiRoot}/`, workflow)
	}

	delete(workflowId: string | number) {
		return this.http.delete(`${this.apiRoot}/${workflowId}`)
	}

	duplicate(workflowId: string | number) {
		return this.http.post(`${this.apiRoot}/${workflowId}/duplicate`, {})
	}

	start(workflowId: string, sendNotifications: boolean) {
		return this.http.post(`${this.apiRoot}/start/${workflowId}`, {
			params: {
				send_notifications: sendNotifications,
			},
		})
	}

	hasActivityPending(workflowId: string | number) {
		return this.http.get(`${this.apiRoot}/hasPending/${workflowId}`)
	}
}
