import {Injectable} from "@angular/core";
import {TrackingRequest} from "../requests/tracking.request";
import {TrackingSearchFiltersModel} from "../../batch-tracking/models/tracking-search-filters.model";
import {Observable} from "rxjs";
import {PaginationModel} from "../models/pagination.model";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable()
export class TrackingFacade {
    constructor(private request: TrackingRequest) {
    }
    private defaultPagination: PaginationModel = {
        last: false,
        pageNumber: 0,
        pageSize: 50,
        totalPages: 0,
        totalElements: 0,
    }
    query(pagination: PaginationModel = this.defaultPagination,filters?: TrackingSearchFiltersModel): Observable<any> {
        if(!!filters){
            const keys = Object.keys(filters);
            keys.map( (key: string) => {
                // @ts-ignore
                if(filters[key] === undefined || filters[key] === "") {
                    // @ts-ignore
                    delete filters[key];
                }
            });
        }
        // return this.request.query(pagination,filters)
        return this.request.query(pagination,filters).pipe(
            map( (result: any) => {
                return {
                    ...result,
                    content: result.content

                };
            })
        );
    }

    getByActivity(activityId: string){
        return this.request.getByActivity(activityId);
    }
    get(trackingId: string) {
        return this.request.get(trackingId);

    }
}
