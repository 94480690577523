import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { DashboardRequest } from "../requests/dashboard.request";
import {map} from "rxjs/operators";

@Injectable()
export class DashboardService {
  private defaultPic: string = '../../../../assets/media/avatars/generic-product.webp';

  constructor(private request: DashboardRequest) {
  }

  getTotalWorkflows(): Observable<any>{
    return this.request.totalWorkflows();
  }
  getActiveWorkflows(): Observable<any>{
    return this.request.totalActiveWorkflows();
  }
  getTotalProducts(): Observable<any>{
    return this.request.totalProducts();
  }
  getActiveProducts(): Observable<any>{
    return this.request.totalActiveProducts();
  }
  getDeliveriedBatches(): Observable<any>{
    return this.request.deliveriedBatches();
  }
  getStepHistogram(): Observable<any>{
    return this.request.getStepHistogram();
  }
  getPendingActivities(): Observable<any>{
    return this.request.getPendingActivities();
  }
  lastCompletedActivities(): Observable<any>{
    return this.request.lastCompletedActivities();
  } getTotalMembers(): Observable<any>{
    return this.request.getTotalMembers();
  }

}
