import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class CountriesRequest {
    constructor(private http: HttpClient) {
    }

    query() {
        return this.http.get(`https://countriesnow.space/api/v0.1/countries/iso`);
    }
}