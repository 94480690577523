import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ToastEventTypes } from "../models/toast-event.types";
import { ToastEventPositions } from "../models/toast-event.positions";
import { LoggerService } from "./logger.service";
import { LogEntryModel } from "../models/log-entry.model";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  toastEvents: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private loggerService: LoggerService) {}

  showSuccessToast(title: string, message: string) {
    const toast = {
      message,
      title,
      type: ToastEventTypes.Success
    };
    this.toastEvents.next(toast);
    this.setLog(toast);
  }

  showInfoToast(title: string, message: string) {
    const toast = {
      message,
      title,
      type: ToastEventTypes.Info,
    };
    this.toastEvents.next(toast);
    this.setLog(toast);
  }

  showWarningToast(title: string, message: string) {
    const toast = {
      message,
      title,
      type: ToastEventTypes.Warning,
    };
    this.toastEvents.next(toast);
    this.setLog(toast);
  }

  showErrorToast(title: string, message: string) {
    const toast = {
      message,
      title,
      type: ToastEventTypes.Error,
      position:ToastEventPositions.topLeft

    };
    this.toastEvents.next(toast);
    this.setLog({ ...toast, type: "danger" });
  }

  hideToast(): void {
    const toast = {
      message: null,
      title: null,
      type: null,
    };
    this.toastEvents.next(toast);
  }

  private setLog(entry: any) {
    const log: LogEntryModel = {
      title: entry.title,
      description: entry.message,
      type: entry.type,
      fecha: Date.now(),
    };
    this.loggerService.addLog(log);
  }
}
