import { AuthModel } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {
  id?: number;
  username?: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  verified: boolean;
  createDate: string;
  birthDate: string;
  address: string;
  gender: string;
  profile: string;
  avatar: [];
  company?: any;
  facility?: any;
  roles: string[];
  imagePath?: string;


  setUser(_user: unknown) {
    const user = _user as UserModel;
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.firstName = user.firstName || '';
    this.lastName = user.lastName || '';
    this.email = user.email || '';
    this.roles = user.roles || [];
    this.address = user.address;
  }
}
