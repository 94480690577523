<div class="row">
  <div class="col-6">
    <div class="row">
      <b class="total-title col-auto">Total Products</b>
      <b class="total-value">4</b>
    </div>
  </div>
  <div class="col-6">
    <div class="row dashboard-filter-buttons justify-content-end" *ngIf="!showSustainabilitySection">
      <div class="col-auto d-flex text-right justify-content-end">
        <button class="col-auto">
          <i class="fa fa-calendar me-1"></i>
          <span><b>Select dates</b></span>
        </button>
      </div>
      <div class="col-auto d-flex text-right justify-content-end">
        <button class="col-auto">
          <i class="fa fa-filter me-1"></i>
          <span><b>Filter</b></span>
        </button>
      </div>
    </div>
    <div class="row dashboard-filter-buttons justify-content-end" *ngIf="showSustainabilitySection">
      <div class="col-auto d-flex text-right justify-content-end">
        <button class="col-auto" (click)="handleSustainabilityScore()">
          <i class="fa fa-arrow-left me-1"></i>
          <span><b>Back</b></span>
        </button>
      </div>
     
    </div>
  </div>
</div>

<div class="card shadow-sm mb-3 animate__animated animate__fadeIn" style="background: rgba(240, 237, 213, 0)">
  <div class="card-body row p-0 m-0" *ngIf="!showSustainabilitySection">
    <div class="row p-0 m-0 h-100">
      <div class="col-4 total-record-block border-right" (click)="goSustainability()">
        <h3 class="total-record-title">Sustainability</h3>
        <div class="row align-items-center mt-2">
          <div class="col-auto">
            <div class="row align-items-center">

             <div class="col-12 mb-2">
             <app-progress-bar [progress]="90"></app-progress-bar>
             </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div
            class="col-12 dashboard-records sustainability-item" 
            *ngFor="let item of products;let ind"
          >
            <div class="row align-items-center p-2 sustainability-item-content">
              <div class="col-8">
                <span class="text-gray-span">{{ item.title }}</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
               <div class="row align-items-center">
                <i *ngIf=" item.value =='100' " class="fa fa-check col-3 total-record-item"></i>
                <small *ngIf=" item.value !='100' " class="col-auto total-record-text"
                >{{item.value}}%</small
              >
                <div *ngIf=" item.value !='100'" class="p-0 col-3  total-record-pending">
                  <svg viewBox="0 0 10 10" width="400" height="400">
                    <defs>
                      <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style="stop-color:#6bab3b;stop-opacity:1" />
                        <stop offset="100%" style="stop-color:#a9eb7e;stop-opacity:1" />
                      </linearGradient>
                    </defs>
                    <circle cx="5" cy="5" r="4" fill="none" stroke-width="0.78" stroke="#B0B0B0" stroke-linecap="round" />
                    <circle #loaderPath class="loader" cx="5" cy="5" r="4" fill="none" stroke-width="0.8" stroke="url(#strokeGradient)" stroke-linecap="round" />
                  </svg>
                </div>
               </div>
              </div>
              <!-- <div class="col-2">70 %</div> -->
            </div>
            <div class="separator separator-dashed my-3"></div>


          </div>
        </div>
      </div>
      <div class="col-4 total-record-block p-5">
       <div class="row m-0 h-100">
        <div class="col-12  p-4 ">
          <h3 class="total-record-title">All activities</h3>
          <div class="row align-items-center mt-5">
            <span class="col-auto total-record-value p-0 me-4"> 5 </span>
            <div class="col-auto">
              <div class="row align-items-center">
                <i class="fa fa-check col-auto total-record-icon"></i>
                <small class="col-auto total-record-text"
                  >2 new completed activities</small
                >
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div
              class="col-12 dashboard-records"
              *ngFor="let item of activityRecords"
            >
              <div class="row">
                <div class="col-8">
                  <span class="text-gray-span">{{ item.title }}</span>
                </div>

                <div class="col-4 d-flex justify-content-end " *ngIf="item.value=='100'">
                  <i class="fa fa-check total-record-item "></i>

                 </div>
                <div class="col-4 d-flex justify-content-end" *ngIf="item.value!='100'">
                  <div class="row align-items-center m-0">
                    <small class="col-auto total-record-text"
                    >{{item.value}}%</small
                  >
                    <div class="p-1 col-3  total-record-pending">
                      <svg viewBox="0 0 10 10" width="200" height="200">
                        <defs>
                          <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style="stop-color:#6bab3b;stop-opacity:1" />
                            <stop offset="100%" style="stop-color:#a9eb7e;stop-opacity:1" />
                          </linearGradient>
                        </defs>
                        <circle cx="5" cy="5" r="4" fill="none" stroke-width="0.78" stroke="#B0B0B0" stroke-linecap="round" />
                        <circle #loaderPath class="loader" cx="5" cy="5" r="4" fill="none" stroke-width="0.8" stroke="url(#strokeGradient)" stroke-linecap="round" />
                      </svg>
                    </div>
                   </div>
                </div>
              </div>
              <hr>
  
            </div>
          </div>
        </div>
        <div class="col-12  p-0">
          <h3 class="total-record-title">All workflows</h3>
          <div class="row align-items-center mt-5">
            <span class="col-auto total-record-value p-0 me-4"> 5 </span>
            <div class="col-auto">
              <div class="row align-items-center">
                <i class="fa fa-arrow-up col-auto total-record-icon"></i>
                <small class="col-auto total-record-text"
                  >27%</small
                >
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div
              class="col-12 dashboard-records"
              *ngFor="let item of workflows"
            >
              <div class="row">
                <div class="col-8">
                  <span class="text-gray-span">{{ item.title }}</span>
                </div>
                <div class="col-4 d-flex justify-content-end" >{{ item.value }}%</div>
              </div>
              <hr>
  
            </div>
          </div>
        </div>
       </div>
      </div>
      <div class="col-4 total-record-block border-right h-100 p-5">
        <div class="row h-100 m-0 p-4">
          <h3 class="total-record-title">Active products</h3>
        <div class="row align-items-center mt-5">
          <span class="col-auto total-record-value p-0 me-4"> 3 </span>
          <div class="col-auto">
            <div class="row align-items-center">
              <i class="fa fa-check col-auto total-record-icon"></i>
              <small class="col-auto total-record-text"
                >2 active products</small
              >
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div
            class="col-12 dashboard-records"
            *ngFor="let item of products"
          >
            <div class="row ">
              <div class="col-8">
                <span class="text-gray-span">{{ item.title }}</span>
              </div>
              <div class="col-4 d-flex justify-content-end">{{ item.value }}%</div>
            </div>
            <hr>

          </div>
        </div>
        </div>
      </div>
     
    
    </div>
  </div>

  <div class="card-body row p-0 m-0 animate__animated animate__fadeInLeft" *ngIf="showSustainabilitySection">
    <app-sustainability-score-dashboard class="p-0" [products]="products"></app-sustainability-score-dashboard>
  </div>


</div>
