import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ProductModel} from "../models";
import {Observable} from "rxjs";
import {PaginationModel} from "../models/pagination.model";

@Injectable()
export class ProductsRequest {
    private apiRoot: string = `${environment.apiUrl}/product/`;

    constructor(private http: HttpClient) {
    }

    query(pagination: PaginationModel, filters?: any): Observable<any>  {
        if(!!filters){
            const keys = Object.keys(filters);
            keys.map( (key: string) => {
                if(filters[key] === undefined) {
                    delete filters[key];
                }
            });
        }
        const route = !!filters ? `${this.apiRoot}s/es/` : this.apiRoot; // TODO -> Implementar multi idioma
        return this.http.get(`${route}`, {
            params: {
                size: pagination.pageSize,
                page: pagination.pageNumber,
                ...filters,
            }
        });
    }

    get(productId: string) {
        return this.http.get(`${this.apiRoot}${productId}/`);
    }

    getActives() {
        return this.http.get(`${this.apiRoot}/actives`);
    }

    create(product: ProductModel) {
        return this.http.post(`${this.apiRoot}`, product);
    }

    update(product: ProductModel) {
        return this.http.put(`${this.apiRoot}`, product);
    }

    delete(productId: string | number) {
        return this.http.delete(`${this.apiRoot}${productId}/`);
    }

    uploadPic(image: any, productId: number, companyId: number | string) {
        const formData: FormData = new FormData();
        formData.set('image', image, image.name);
        formData.set('idProduct', productId+'');
        formData.set('idCompany', companyId+'');
        return this.http.post(`${this.apiRoot}image/`, formData)
    }

    getImageDynamic(ean: string, batchId: string, productId: string){
        // if (ean == "1"){
        //     return this.http.get(`${this.apiRoot}qrcode/generate/?ean=${ean}&batchId=${batchId}&idProduct=${productId}`);
        // } else {
        //     return this.http.get(`${this.apiRoot}qrcode/generate/?ean=${ean}&batchId=${batchId}`);
        // }
        return this.http.get(`${this.apiRoot}qrcode/generate/?ean=${ean}&batchId=${batchId}&idProduct=${productId}`);

    }
}
