import {Component, EventEmitter, Input, Output} from "@angular/core";
import {
    StelvioGridSettingsColumnModel,
    StelvioGridSettingsModel,
} from "../stelvio-grid-settings.model";
import {Observable, of} from "rxjs";
import {resolveObjectPath} from "../../../../../utils/functions";

@Component({
    selector: "stelvio-grid-body",
    templateUrl: "./stelvio-grid-body.component.html",
    styleUrls: [
        "./stelvio-grid-body.component.scss",
        "../../../../../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css",
    ],
})
export class StelvioGridBodyComponent {
    @Input() settings: StelvioGridSettingsModel;
    @Input() data: Observable<any[]>;
    @Input() message_no_data: string = "Data not found";
    @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChildAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSort: EventEmitter<any> = new EventEmitter<any>();
    toolbarButtonMarginClass = "ms-1 ms-lg-3";
    toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";
    sortOrder: "asc" | "desc" = "asc";
    sortField: string = "id";
    badgeStatus:any;

    constructor() {
    }

    ngOnInit() {
        if (this.settings.sortedColumn) {
            this.sortField = this.settings.sortedColumn.split('-')[0]
            this.sortOrder = this.settings.sortedColumn.split('-')[1] == "asc" ? "asc" : "desc"
        }
    }

    getChildrenSettings(children: StelvioGridSettingsColumnModel[]) {
        return {
            labels: {
                title: "",
                newBtn: "",
            },
            columns: children,
            children: [],
        };
    }

    getChildrenData(data: any) {
        return of(data);
    }

    getBadgeClass(badgeType: any) {
        return {
            'badge-active': !badgeType,
            [`badge-${badgeType}`]: badgeType,
        };
    }
    getFieldBadgeClass(value: any,badgeType:any) {
        if(value=='Inactive'||value=='Discontinued'||value=='Disabled'){
            return 'badge-light-danger';
        }else{
            return [`badge-${badgeType}`]
        }

    }
    toggleRow(row: any) {
        row.open = row.open === undefined ? true : !row.open;
    }

    fireAction(event: { id: number; option: any; item: any }) {
        this.onAction.emit(event);
    }

    fireChildAction(id: number, type: string) {
        this.onChildAction.emit({id, type});
    }

    getField(field: string | undefined, obj: any) {

        if (!field) return null;
        return resolveObjectPath(field, obj);
    }

    toggleSort(column: any) {
        if (column.sortable) {
            this.sortField = column.field;
            this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            this.onSort.emit({
                field: column.sortField || column.field,
                order: this.sortOrder,
            });
        }
    }

    getIconClass(field: string): string {
        if (this.sortField == field) {
            if (this.sortOrder === "asc") {
                return "fa-sort-up";
            } else {
                return "fa-sort-down";
            }
        } else {
            return "fa-sort";
        }
    }
}
