import { Injectable } from "@angular/core";
import { CompaniesRequest } from "../requests/companies.request";
import { CompanyModel } from "../models";
import { map } from "rxjs/operators";
import { PaginationModel } from "../models/pagination.model";
import { DefaultPaginationSettings } from "../config/default-pagination.settings";
import {InvitationRequestModel, NewCompanyInvitationModel} from "../models/company.model";

@Injectable()
export class CompaniesFacade {
  private genericProfilePicPath: string =
    "../../../../assets/media/avatars/company-generic.png";
  constructor(private request: CompaniesRequest) {}

  query(
    pagination: PaginationModel = DefaultPaginationSettings,
    filters?: any
  ) {
    return this.request.query(pagination, filters).pipe(
      map((response: any) => {
        return {
          ...response,
          content: response.content.map((company: any) =>
            this._buildPicturePath(company)
          ),
        };
      })
    );
  }

  get(id: string) {
    return this.request
      .get(id)
      .pipe(map((company: any) => this._buildPicturePath(company)));
  }

  update(company: CompanyModel) {
    return this.request.update(company);
  }

  create(company: CompanyModel) {
    return this.request.create(company);
  }

  sendInvitationRequest(invitation: InvitationRequestModel) {
    return this.request.sendInvitationRequest(invitation);
  }

  sendInvitationNewCompany(invitation: NewCompanyInvitationModel) {
    return this.request.sendInvitationNewCompany(invitation);
  }

  handleInvitation(invitationId: string | number,action:string) {
    return this.request.handleInvitation(invitationId,action);
  }

  delete(id: string | number) {
    return this.request.remove(id);
  }

  uploadLogo(image: any, companyId: number) {
    return this.request.uploadLogo(image, companyId);
  }
  uploadImages(images: any, companyId: number) {
    return this.request.uploadImages(images, companyId);
  }
  deleteImage(imageId:number) {
    return this.request.deleteImage(imageId);
  }
  getAll() {
    return this.request.all();
  }

  private _buildPicturePath(company: any) {
    const picturePath = !!company?.logo?.path
      ? `/images/${company.id}/${company.logo.path}`
      : this.genericProfilePicPath;
    return {
      ...company,
      picturePath,
      contactButtons: this._buildContactButons(company),
    };
  }

  private _buildContactButons(company: any): any[] {
    const contactBtns = [];
    if (!!company.phone) {
      contactBtns.push({
        link: company.phone,
        tooltip: company.phone,
        class: "btn-phone",
        label: "",
        icon: "assets/media/svg/icons/call.svg",
      });
    }
    if (!!company.website) {
      contactBtns.push({
        link: company.website,
        tooltip: company.website,
        class: "btn-web",
        label: "",
        icon: "assets/media/svg/icons/attachment1.svg",
      });
    }
    if (!!company.email) {
      contactBtns.push({
        link: company.email,
        tooltip: company.email,
        class: "btn-email",
        label: "",
        icon: "assets/media/svg/icons/mail.svg",
      });
    }
    return contactBtns;
  }

  getImages(companyId:number){
    return this.request.getImages(companyId);
  }
}
