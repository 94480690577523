import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {TrackingSearchFiltersModel} from "../../batch-tracking/models/tracking-search-filters.model";
import {Observable, of} from "rxjs";
import * as trackingList from './tracking-list-demo.json';
import * as trackingItem from './tracking-item-demo.json';
import {PaginationModel} from "../models/pagination.model";

@Injectable()
export class TrackingRequest {
    private apiRoot: string = `${environment.apiUrl}/tracking/`;

    constructor(private http: HttpClient) {
    }

    query(pagination: PaginationModel,filters?: TrackingSearchFiltersModel): Observable<any> {
        return this.http.get(`${this.apiRoot}`, {
            params: {
                size: pagination.pageSize,
                page: pagination.pageNumber,
                ...filters
            }
        });
    }

    get(trackingId: string) {
        // @ts-ignore
        //return of(trackingItem.default);
        return this.http.get(`${this.apiRoot}${trackingId}/`);
    }

    getByActivity(activityId: string) {
        // @ts-ignore
        //return of(trackingItem.default);
        return this.http.get(`${this.apiRoot}activity/${activityId}`);
    }

}
