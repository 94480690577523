import {Component,OnInit} from "@angular/core";

@Component({
    selector: 'stelvio-spinner',
    templateUrl: './stelvio-spinner.component.html',
    styleUrls: ['./stelvio-spinner.component.scss']
})
export class StelvioSpinnerComponent implements OnInit{
    isLoading:boolean = true;
    constructor() {


    }
    ngOnInit() {
        const logo = document.getElementById("logo");
        if(logo){
            const fadeInAnimation = logo.style.animation;

            logo.style.animation = "none";
            logo.offsetHeight; // trigger reflow

            logo.style.animation = fadeInAnimation;
        }
    }

    closeSpinner(){
       this.isLoading = false
    }
}