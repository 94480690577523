<div class="page" style="width: 100%;height: 100vh;" #cardContainer>
    <div class="card card-flush">
        <div class="card-header">
            <div class="card-title">
                <div class="row">
                    <h2 class="w-100">Workflow {{workflowInstance ? workflowInstance.workflowName : ''}}</h2>
                    <small class="w-100">Workflow
                        instance {{workflowInstance ? workflowInstance.workflowInstanceStep.name : ''}}</small>
                </div>
            </div>
        </div>
        <div class="card-body pt-1" style="height: 100vh">
            <div class="row bg bg-primary p-2">
               <div class="col-auto">
                   <button class="btn btn-light" (click)="zoomIn()"><i style="font-size: 2rem" class="fa-solid fa-magnifying-glass-plus"></i></button>
               </div>
                <div class="col-auto">
                    <button class="btn btn-light" (click)="zoomOut()"><i style="font-size: 2rem" class="fa-solid fa-magnifying-glass-minus"></i></button>
                </div>
                <div class="col-auto">
                    <button class="btn btn-light" (click)="setAutoCenter()"><i style="font-size: 2rem" class="fa-solid fa-down-left-and-up-right-to-center"></i></button>
                </div>
            </div>
            <ngx-graph
                    *ngIf="activeFlowchart"
                    [links]="links"
                    [nodes]="nodes"
                    [layout]="layout"
                    [curve]="curve"
                    [panToNode$]="center$"
                    [draggingEnabled]="draggingEnabled"
                    [panningEnabled]="panningEnabled"
                    [enableZoom]="zoomEnabled"
                    [zoomSpeed]="zoomSpeed"
                    [zoomLevel]="zoomLevel"
                    [layoutSettings]="layoutSettings"
                    [minZoomLevel]="minZoomLevel"
                    [maxZoomLevel]="maxZoomLevel"
                    [panOnZoom]="panOnZoom"
                    [autoZoom]="autoZoom"
                    [autoCenter]="autoCenter"
                    [update$]="update$"
                    [center$]="center$"
                    [zoomToFit$]="zoomToFit$"

            >
                <ng-template #nodeTemplate let-node>
                    <svg:g class="node">
                        <svg:foreignObject width="200" height="100" style="min-height:100px;height:100px;">
                            <xhtml:div class="cardContainer p-2 h-100" style="overflow-y:auto;border:none;border-left: 6px solid {{node.color? node.color:'black'}}"
                                       xmlns="http://www.w3.org/1999/xhtml">
                                <h5 *ngIf="!node.sub_step" class="name w-100">{{node.label}}</h5>
                                <!--                               <div  *ngIf="!node.sub_step" style="border-top: 2px solid gray;margin-bottom: 2%"></div>-->
                                <h5 class="name w-100">{{ node.product_name}}</h5>
                                <!--                               <div *ngIf="node.sub_step" style="border-top: 2px solid gray;margin-bottom: 2%"></div>-->
                                <label *ngIf="node.sub_step" class="name w-100">Batch In: {{ node.batch_in}}</label>
                                <label *ngIf="node.batch_out" class="name w-100">Batch Out: {{ node.batch_out}}</label>
                            </xhtml:div>
                        </svg:foreignObject>

                    </svg:g>
                </ng-template>


            </ngx-graph>
        </div>
    </div>

</div>