<div class="row">
    <div class="col-5">
        <label class="form-label fs-6 text-dark">
            Workflow<span class="required"></span>
            <select class="form-control" formControlName="workflow">
                <option [value]="option.id" *ngFor="let option of settings.workflows">{{option.label}}</option>
            </select>
            <span class="field-description">Select the workflow where this member will participate.</span>
        </label>
    </div>
    <div class="col-5">
        <label class="form-label fs-6 text-dark">
            Step<span class="required"></span>
            <select class="form-control" formControlName="step">
                <option [value]="step.id" *ngFor="let step of settings.currentWorkflow.steps">{{step.label}}</option>
            </select>
            <span class="field-description">Select the workflow where this member will participate.</span>
        </label>
    </div>
    <div class="col-2">
        <button class="btn btn-sm" [ngClass]="settings.actionType">
            Save
        </button>
    </div>
</div>