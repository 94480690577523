<div class="task-overview-wrapper">
    <div class="task-overview-body">
        <span class="task-overview-body__title" (click)="fireAction()">{{settings.title}}</span>
        <span class="task-overview-body__description">{{settings.description}}</span>
        <span class="task-overview-body__text">{{settings.text}}</span>
    </div>
    <div class="task-overview-counter" [ngClass]="settings.classes">
        <span class="task-overview-counter__text">{{settings.counter}}</span>
        <span class="task-overview-counter__label">{{settings.counterLabel}}</span>
    </div>
</div>