<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="Descripción breve y relevante de tu sitio web">
    <meta name="author" content="Stelviotech">
    <meta name="keywords" content="Palabra clave 1, Palabra clave 2, Palabra clave 3">
    <meta name="robots" content="index, follow">
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5">
    <meta name="msapplication-TileColor" content="#da532c">
    <meta name="theme-color" content="#ffffff">
</head>
<body>

<div class="container w-100 h-100 m-0">
    <div id="toolbar-curve" role="contentinfo"  style="">
        <div class="wrap" style=" background:{{webappConfig.main_color}};
        background: linear-gradient(90deg, {{webappConfig.main_color}} 0%, rgba(250,250,250,1) 55%);"><p></p></div>
    </div>
    <div class="row justify-content-center wepapp-view-header w-100 m-0">
        <div class="row justify-content-end position-absolute share-button">
            <div class="col-2 text-end p-5 m-3">
                <i class="fs-1 fa-solid fa-share-from-square text-dark "></i>
            </div>
        </div>
        <div class="row m-0 w-100  align-items-center p-3 product-header">
            <div class="col-7 product-image-webapp">
                <div>
                    <img width="100%" src="/assets/media/pata-jamon.png" alt="">
                </div>
            </div>
            <div class="col-5">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <span class="text-center fs-1"><b>4.8</b></span>
                    </div>
                    <div class="col-12 my-2">
                        <span class="text-center">
                            <div class="row justify-content-around">
                                <i class="col-auto fa-solid fa-star p-0 text-warning"></i>
                                <i class="col-auto fa-solid fa-star p-0 text-warning"></i>
                                <i class="col-auto fa-solid fa-star p-0 text-warning"></i>
                                <i class="col-auto fa-solid fa-star p-0 text-warning"></i>
                                <i class="col-auto fa fa-star p-0"></i>
                            </div>
                        </span>
                    </div>
                    <div class="col-12 text-center">
                        <span class="text-center text-secondary"><a class="text-center text-secondary" href="/valorations">4 Valorations</a></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-4">
                <button class="btn valorate-button">
                    <i class="fa fa-star text-warning"></i>
                    Valorar
                </button>
            </div>
        </div>

        <div class="row mt-3">
            <h1 class="mb-2">Jamón de Teruel D.O.P.</h1>
            <span>Teruel, España</span>
        </div>

        <div class="row w-100 justify-content-center mt-5">
            <nav class="row w-100 justify-content-center">
                <div class="nav nav-tabs row w-100 justify-content-center" id="nav-tab" role="tablist">
                    <button class="col-6 nav-link active bg-secondary text-white" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Historia</button>
                    <button class="col-6 nav-link text-secondary bg-outline-secondary" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Detalles</button>
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">...</div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
            </div>
        </div>


        <div class="row">
            <div class="col-3"><i class="fs-1 fa-brands fa-instagram"></i></div>
            <div class="col-3"><i class="fs-1 fa-brands fa-facebook"></i></div>
            <div class="col-3"><i class="fs-1 fa-brands fa-linkedin"></i></div>
        </div>

    </div>
</div>
</body>
</html>


