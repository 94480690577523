import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {WorkflowModel} from "../models";
import {PaginationModel} from "../models/pagination.model";


@Injectable()
export class WorkflowRequest {
    private apiRoot: string = `${environment.apiUrl}/workflow`;

    constructor(private http: HttpClient) {
    }

    create(workflow: WorkflowModel) {
        return this.http.post(`${this.apiRoot}/`, workflow);
    }

    get(id: string) {
        return this.http.get(`${this.apiRoot}/${id}/`);
    }

    query(pagination: PaginationModel, params?: any) {
        return this.http.get(`${this.apiRoot}/`, {
            params: {
                size: pagination.pageSize,
                page: pagination.pageNumber,
                ...params,
            }
        });
    }

    find(workflowId: string) {
        return this.http.get(`${this.apiRoot}/${workflowId}`);
    }

    update(workflow: WorkflowModel) {
        return this.http.put(`${this.apiRoot}/`, workflow);
    }

    delete(workflowId: string | number) {
        return this.http.delete(`${this.apiRoot}/${workflowId}`);
    }
    duplicate(workflowId: string | number) {
        return this.http.post(`${this.apiRoot}/${workflowId}/duplicate`,{});
    }
    start(workflowId: string, sendNotifications: boolean) {
        return this.http.post(`${this.apiRoot}/start/${workflowId}`, {
            params: {
                send_notifications: sendNotifications
            }
        });
    }

    hasActivityPending(workflowId: string | number) {
        return this.http.get(`${this.apiRoot}/hasPending/${workflowId}`);
    }
}
