import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartComponent} from "ng-apexcharts";
import {
    ApexNonAxisChartSeries,
    ApexResponsive,
    ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
    series: ApexNonAxisChartSeries | any;
    chart: ApexChart | any;
    responsive: ApexResponsive[] | any;
    labels: any;
    colors: any;
    dataLabels: any;
};

@Component({
    selector: 'app-total-doughnut-chart',
    templateUrl: './total-doughnut-chart.component.html',
    styleUrls: ['./total-doughnut-chart.component.scss']
})

export class TotalDoughnutChartComponent implements OnInit {

    @Input('series') series: any = [];
    @Input('labels') labels: any;
    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;

    constructor() {

        this.chartOptions = {
            series: this.series,
            chart: {
                type: "donut"
            },
            colors: ["rgba(211, 252, 160, 0.2)", "#0A3400"],
            labels: this.labels,
            dataLabels: {
                enabled: true,
                enabledOnSeries: undefined,
                formatter: function (val: any, opts: any) {
                    return val.toFixed(2) +'%'
                },
                textAnchor: 'middle',
                distributed: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: ["#0A3400"]
                },
                background: {
                    enabled: true,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 0.9,
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                },
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ]
        };
    }

    ngOnInit(): void {
        this.chartOptions.series = this.series
        this.chartOptions.labels = this.labels



    }
}
