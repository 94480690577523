import { Injectable } from '@angular/core'
import { WorkflowModel } from '../models'
import { map } from 'rxjs/operators'
import { WorkflowDataTypes, WorkflowStepDataModel } from '../models/workflow-step.model'
import { PaginationModel } from '../models/pagination.model'
import { environment } from '../../../../environments/environment'
import { SustainabilityFrameworkRequest } from '../requests/sustainability_framework.request'
import { Observable } from 'rxjs'

@Injectable()
export class SustainabilityFrameworkFacade {
	private defaultPagination: PaginationModel = {
		last: false,
		pageNumber: 0,
		pageSize: environment.pagination.pageSize,
		totalPages: 0,
		totalElements: 0,
	}
	private defaultPic = '../../../../assets/media/avatars/company-generic.png'

	constructor(private request: SustainabilityFrameworkRequest) {}

	query(pagination: PaginationModel = this.defaultPagination, filters?: any) {
		return this.request.query(pagination, filters).pipe(
			map((result: any) => {
				return {
					...result,
					content: result.content.map((framework: any) => this._parseResponse(framework)),
				}
			}),
		)
	}

	get(frameworkId: string): Observable<any> {
		return this.request.get(frameworkId)
	}

	create(framework: any) {
		return this.request.create(framework)
	}

	update(framework: any) {
		return this.request.update(framework)
	}

	delete(frameworkId: string | number) {
		return this.request.delete(frameworkId)
	}

	private _parseResponse(framework: any) {
		const steps = framework.kpis
			.sort((a: any, b: any) => a.stepOrder - b.stepOrder)
			.map((step: any) => {
				return {
					...step,
					visibility: this.parseVisibility(step),
					children: step.children
						.map((field: WorkflowStepDataModel) => {
							return {
								...field,
								visibility: this.parseVisibility(field),
								fieldType: WorkflowDataTypes[field.workflowDataType],
							}
						})
						.sort((a: any, b: any) => a.fieldOrder < b.fieldOrder),
				}
			})
		let companyImagePath: string = this.defaultPic
		if (framework?.company?.logo?.path) {
			const companyLogo = framework?.company?.logo?.path
			companyImagePath = `/images/${framework.company.id}/${companyLogo}`
		}
		return {
			...framework,
			workflowSteps: steps,
			children: steps,
			status: framework.enabled ? 'Active' : 'Inactive',
			badgeType: framework.enabled ? 'active' : 'inactive',
			companyImagePath,
		}
	}

	parseVisibility(item: any) {
		if (item._public) {
			return 'public'
		}
		if (item._private) {
			return 'private'
		}
		if (item.viewer) {
			return 'viewer'
		}
	}

	hasActivityPending(workflowId: string | number) {
		return this.request.hasActivityPending(workflowId)
	}
}
