import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable()
export class BatchsRequest {
    private apiRoot: string = `${environment.apiUrl}/batch`;
    constructor(private http: HttpClient) {
    }

    get(batchId: string) {
        return this.http.get(`${this.apiRoot}/${batchId}`);
    }

    getLocator(batchId: string) {
        return this.http.get(`${this.apiRoot}/locator/${batchId}`);
    }
}