import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {UserModel} from '../../models/user.model';
import {environment} from '../../../../../environments/environment';
import {AuthModel} from '../../models/auth.model';
import {catchError, finalize, map} from "rxjs/operators";

const API_USERS_URL = `${environment.apiUrl}/auth`;

@Injectable({
    providedIn: 'root',
})
export class AuthHTTPService {
    constructor(private http: HttpClient) {
    }

    // public methods
    login(email: string, password: string): Observable<any> {
        const body = new HttpParams()
            .set('grant_type', environment.auth.grantType)
            .set('client_id', environment.auth.clientId)
            .set('client_secret', environment.auth.clientSecret)
            .set('username', email)
            .set('password', password);
        return this.http.post(`${environment.auth.apiURL}/realms/bbtwins/protocol/openid-connect/token`,
            body.toString(),
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            }).pipe(
            map((response: any) => {
                const auth = new AuthModel();
                auth.authToken = response.access_token;
                auth.refreshToken = response.refresh_token;
                auth.expiresIn = new Date(Date.now() + response.expires_in);
                return auth;
            }),
            catchError(e => of(e)),
        );
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: UserModel): Observable<UserModel> {
        return this.http.post<UserModel>(API_USERS_URL, user);
    }

    // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
    forgotPassword(email: string): Observable<boolean> {
        return this.http.post<boolean>(`/api/user/reset-password/mail`, {
            email: email,
        });
    }

    getUserByToken(token: string): Observable<UserModel> {
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        return this.http.get<UserModel>(`${environment.apiURL}/user/me`, {
            headers: httpHeaders,
        });
    }

    refreshToken(token: string): Observable<AuthModel> {
        const body = new HttpParams()
            .set('grant_type', environment.auth.refreshGrantType)
            .set('client_id', environment.auth.clientId)
            .set('client_secret', environment.auth.clientSecret)
            .set('refresh_token', token);
        return this.http.post<any>(`${environment.auth.apiURL}/realms/bbtwins/protocol/openid-connect/token`, body.toString(),
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            }).pipe(
            map((response: any) => {
                const auth = new AuthModel();
                auth.authToken = response.access_token;
                auth.refreshToken = response.refresh_token;
                auth.expiresIn = new Date(Date.now() + response.expires_in);
                return auth;
            }),
            catchError(e => of(e)),
        );
    }

    resetPassword(password: string, token: string): Observable<boolean> {
        return this.http.put<boolean>(`/api/user/reset-password/`, {
            password: password,
            token: token,
        });
    }

    checkPassworkResetToken(token: string): Observable<boolean> {
        return this.http.get<boolean>(`/api/user/check/token/${token}`);
    }
}
