import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable()
export class CommonRequest {
    private apiRoot: string = `${environment.apiUrl}`;

    constructor(private http: HttpClient) {
    }

   
    getTemplates() {
        return this.http.get(`${this.apiRoot}/template`)
    }
}