<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{settings.title}}</h4>
    <button type="button" class="btn-close" aria-describedby="modal-title" (click)="fireAction('close', 'cross clicked')"></button>
</div>
<div class="modal-body">
    <div *ngIf="!!settings.descriptionHTML" [innerHTML]="settings.descriptionHTML"></div>
    <div *ngIf="!!settings.description">{{settings.description}}</div>
    <div *ngIf="!!settings.component">{{settings.description}}</div>
    <div *ngIf="!!settings.component">{{settings.description}}</div>
</div>
<div class="modal-footer">
    <button type="button"
            [class]="button.classes"
            *ngFor="let button of settings.buttons"
            (click)="fireAction(button.action)">{{button.label}}</button>
</div>