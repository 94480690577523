<div class="w-100">
    <div id="chart" class="row align-items-center">
        <apx-chart
                *ngIf="series.length>0"
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [colors]="chartOptions.colors"
                [labels]="chartOptions.labels"
                [responsive]="chartOptions.responsive"
                [dataLabels]="chartOptions.dataLabels"
        ></apx-chart>
    </div>
</div>