<div class="row">
    <!--begin::Tables Widget 9-->
    <div class="card card-xl-stretch mb-5 mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bold fs-3 ">Last 5 completed activities</span>
<!--                <span class="text-muted mt-1 fw-semibold fs-7"><a href="/activities">Show more</a></span>-->
            </h3>

        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-0">
            <!--begin::Table container-->
            <div class="table-responsive">
                <!--begin::Table-->
                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <!--begin::Table head-->
                    <thead>
                    <tr class="fw-bold text-muted">

                        <th class="min-w-200px">Activity</th>
                        <th class="min-w-150px">Date</th>
                        <th class="min-w-150px">Product</th>
                        <th class="min-w-150px">Batch Id</th>
                        <th class="min-w-100px text-end"></th>
                    </tr>
                    </thead>
                    <!--end::Table head-->
                    <!--begin::Table body-->
                    <tbody>
                    <tr *ngFor="let activity of lastActivities">

                        <td>
                            <div class="d-flex align-items-center">
                                <div class="symbol symbol-45px me-5" *ngIf="activity.products[0].image">
                                    <img src="/images/{{activity.companyId}}/{{activity.products[0].image.path}}" alt="">
                                </div>
                                <div class="d-flex justify-content-start flex-column">
                                    <a href="#" class="text-dark fw-bold text-hover-primary fs-6">{{activity.workflowName}}</a>
                                    <span class="text-muted fw-semibold text-muted d-block fs-7">{{activity.productName}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <a href="#" class="text-dark fw-bold text-hover-primary d-block fs-6">{{activity.createdAt}}</a>
                        </td>
                        <td>
                            <a href="#" class="text-dark fw-bold text-hover-primary d-block fs-6">{{activity.products[0].title}}</a>
                            <span class="text-muted fw-semibold text-muted d-block fs-7">{{activity.ean}}</span>
                        </td>
                        <td>
                            <a href="#" class="text-dark fw-bold text-hover-primary d-block fs-6">{{activity.batchOut[0].name}}</a>
                        </td>
                        <td>
                            <div class="d-flex justify-content-end flex-shrink-0">
                                <a href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                   <i class="fa fa-share-square"></i>
                                </a>

                            </div>
                        </td>
                    </tr>


                    </tbody>
                    <!--end::Table body-->
                </table>
                <div *ngIf="lastActivities.length == 0" class="row justify-content-center w-100">
                    <span class=" fs-3 text-center p-4">Data not found</span>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Table container-->
        </div>
        <!--begin::Body-->
    </div>
    <!--end::Tables Widget 9-->
</div>