<div class="row p-0 m-0 sustainability-score-section">
  <div class="col-4 total-record-block sustainability-col">
    <h3 class="total-record-title">Sustainability Score</h3>
    <div class="row align-items-center mt-2">
      <div class="col-auto">
        <div class="row align-items-center">
          <div class="col-12">
            <app-progress-bar [progress]="90"></app-progress-bar>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div
        class="col-12 dashboard-records sustainability-item" 
        *ngFor="let item of products;let ind"
      >
        <div class="row align-items-center p-2 sustainability-item-content">
          <div class="col-8">
            <span class="text-gray-span">{{ item.title }}</span>
          </div>
          <div class="col-4 d-flex justify-content-end">
           <div class="row align-items-center">
            <i *ngIf=" item.value =='100' " class="fa fa-check col-3 total-record-item"></i>
            <small *ngIf=" item.value !='100' " class="col-auto total-record-text"
            >{{item.value}}%</small
          >
            <div *ngIf=" item.value !='100'" class="p-0 col-3  total-record-pending">
              <svg viewBox="0 0 10 10" width="400" height="400">
                <defs>
                  <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style="stop-color:#6bab3b;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#a9eb7e;stop-opacity:1" />
                  </linearGradient>
                </defs>
                <circle cx="5" cy="5" r="4" fill="none" stroke-width="0.78" stroke="#B0B0B0" stroke-linecap="round" />
                <circle #loaderPath class="loader" cx="5" cy="5" r="4" fill="none" stroke-width="0.8" stroke="url(#strokeGradient)" stroke-linecap="round" />
              </svg>
            </div>
           </div>
          </div>
          <!-- <div class="col-2">70 %</div> -->
        </div>
        <div class="separator separator-dashed my-3"></div>


      </div>
    </div>
  </div>

  <div class="col-4 total-record-block  p-5">
    <div class="row m-0 h-100 ">
      <div class="col-12 p-4 co2-footprint ">
        <div class="row m-0 h-100">
          <div class="col-12">
            <h3 class="total-record-title">CO2 Footprint</h3>
          </div>
          <div class="col-12">
            <div class="row align-items-center mt-5">
              <span class="col-auto total-record-value p-0 me-4"> 20% </span>
              <div class="col-auto">
                <div class="row align-items-center">
                  <i class="fa fa-arrow-up col-auto total-record-icon"></i>
                  <small class="col-auto total-record-text">0.5%</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 p-0">
                <small class="text-gray"
                  >Percentage reduction achieved through sustainable
                  practices</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 p-4 energy-con">
        <div class="row m-0 h-100">
          <div class="col-12">
            <h3 class="total-record-title">Energy Consumption</h3>
          </div>
          <div class="col-12">
            <div class="row align-items-center mt-5">
              <span class="col-auto total-record-value p-0 me-4"> 67% </span>
              <div class="col-auto">
                <div class="row align-items-center">
                  <i class="fa fa-arrow-up col-auto total-record-icon"></i>
                  <small class="col-auto total-record-text">27%</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 p-0">
                <small class="text-gray"
                  >Energy consumption usage per unit of production</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4 total-record-block border-right p-5">
    <div class="row m-0 h-100 ">
      <div class="col-12 p-4 water-efficiency ">
        <div class="row m-0 h-100">
          <div class="col-12">
            <h3 class="total-record-title">Water Efficiency</h3>
          </div>
          <div class="col-12">
            <div class="row align-items-center mt-5">
              <span class="col-auto total-record-value p-0 me-4"> 15% </span>
              <div class="col-auto">
                <div class="row align-items-center">
                  <i class="fa fa-arrow-up col-auto total-record-icon"></i>
                  <small class="col-auto total-record-text">19%</small>
                 
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 p-0">
                <small class="text-gray"
                  >Percentage improvement in water use efficiency</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-4 pesticide border-right border-right-1">
        <div class="row m-0 h-100">
          <div class="col-12">          <h3 class="total-record-title">Pesticide and Fertilizer Usage</h3>
          </div>
          <div class="col-12">
            <div class="row align-items-center mt-5">
              <span class="col-auto total-record-value p-0 me-4"> 12% </span>
              <div class="col-auto">
                <div class="row align-items-center">
                  <i class="fa fa-arrow-up col-auto total-record-icon"></i>
                  <small class="col-auto total-record-text">7%</small>
              
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 p-0">
                <small class="text-gray"
                  >Pesticide and Fertilizer Usage average performance</small
                >
              </div>
            </div>
          </div>
       
        </div>
      </div>
    </div>
  </div>
</div>
