// Spain
export const locale = {
  lang: "es",
  data: {
    app: {
      dashboard: {
        titles: {
          products: "Productos",
          workflows: "Flujos de trabajo",
          members: "Miembros",
          tracedByConsumer: "Traced por el consumidor",
          workflowStatus: "Estado del Flujo de Trabajo",
          pendingTasks: "Tareas pendientes",
        },
        buttons: {
          completeReport: "Ver reporte completo",
        },
      },
      "account-settings": {
        title: "Account Settings",
        "organization-settings": {
          logo: "Company Logo:",
          phone: "Phone #:",
          company: "Company:",
          website: "Website:",
          address: "Address:",
          city: "City:",
          state: "State:",
          country: "Country:",
          zip: "ZIP Code:",
          errors: {
            logo: "",
            phone: "",
            company: "",
            website: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zip: "",
          },
        },
        "individual-settings": {
          name: "Name:",
          lastname: "Last name:",
          email: "E-mail:",
          phone: "Phone #:",
          dob: "Date of Birth:",
          gender: "Gender:",
          city: "City:",
          state: "State:",
          address: "Address:",
          address2: "Address details:",
          country: "Country:",
          zip: "ZIP Code:",
          errors: {
            name: "",
            lastname: "",
            email: "",
            phone: "",
            dob: "",
            gender: "",
            city: "",
            state: "",
            address: "",
            address2: "",
            country: "",
            zip: "",
          },
        },
        "general-settings": {
          "default-language": "Default Language:",
          "enable-error-fixing": "Enable error fixing:",
          "enable-tracking-color-change": "Enable tracking color change:",
          "enable-nested-workflow:": "Enable nested workflows:",
          "xapi-key": "xAPI Key:",
          smtp: "SMTP:",
          sandbox: "Sandbox:",
          "test-email": "Test E-mail:",
          "add-to-members-inbox": "Add to members inbox:",
          errors: {
            "default-language": "",
            "enable-error-fixing": "",
            "xapi-key": "",
            smtp: "",
            sandbox: "",
            "test-email": "",
            "add-to-members-inbox": "",
          },
        },
        reports: {
          logo: "Logo:",
          "browser-tag": "Browser Tag:",
          "report-language-list": "Report language list:",
          "report-searchform-content": "Report searchform content:",
          "search-button-color": "Search button color:",
          "page-not-found-content": "Page not found content:",
          errors: {
            logo: "",
            "browser-tag": "",
            "report-language-list": "",
            "report-searchform-content": "",
            "search-button-color": "",
            "page-not-found-content": "",
          },
        },
      },
      tracking: {
        "tracking-details": {
          "more-details-btn": "More details",
          "show_evidence": "Evidence",

        },
      },
      products: {
        title: "Productos",
        detailsTitle: "Información del producto",
        settingsTitle: "Detalles del producto",
        batchesTitle: "Tandas del producto",
        activitiesTitle: "Actividad",
        grid: {
          title: "Productos listados",
          newBtn: "Nuevo Producto",
          columns: {
            product: "Producto",
            ean: "EAN",
            qr: "QR",
            status: "Estado",
            actions: "Acciones",
          },
          searchFields: {
            productName: "Nombre del producto",
            productCategory: "Categoría del producto",
            ean: "EAN",
          },
        },
        fields: {
          name: {
            label: "Nombre del producto",
            description:
              "El nombre del producto es obligatorio y se recomienda que sea único.",
          },
          company: {
            label: "Compania",
            description:
              "La companía a la que pertenece el producto es obligatoria.",
          },
          ean: {
            label: "EAN",
            description: "Código EAN del producto(Código de barras)",
          },
          description: {
            label: "Descripción del producto",
            description:
              "Se recomienda usar una descripción, esta aparecerá en la app del consumidor.",
          },
          category: {
            label: "Categoría",
            description: "Categoría a la que pertenece el producto.",
          },
          color: {
            label: "Color",
            description: "Color del producto.",
          },
          externalId: {
            label: "Id externo",
            description: "Id externo.",
          },
          width: {
            label: "Ancho",
            description: "Ancho del producto.",
          },
          weight: {
            label: "Peso",
            description: "Peso del producto.",
          },
          diameter: {
            label: "Diametro",
            description: "Diametro del producto.",
          },
          height: {
            label: "Alto",
            description: "Alto del producto.",
          },
          length: {
            label: "Largo",
            description: "Largo del producto.",
          },
          status: {
            label: "Estado",
            description:
              "Establece el estado del producto. Si un producto está desactivado, cualquier tanda del mismo no continuará con ningún flujo de trabajo.",
            options: {
              active: "Activo",
              inactive: "Inactivo",
            },
          },
          qrType: {
            label: "Tip de QR",
            description:
              "Set the QR type. A dynamic QR is used for ...An static QR is used when....",
            options: {
              dynamic: "Dinámico",
              static: "Estático",
            },
          },
          qrDensity: {
            label: "Densidad del QR",
            description:
              "Define la densidad del QR. Una denbsidad alta permitirá una lectyra facil para miembros y clientes.",
            options: {
              high: "Alta",
              low: "Baja",
            },
          },
        },
      },
      productCategories: {
        title: "Categorías",
        grid: {
          title: "Categorías listadas",
          newBtn: "Nueva Categoría",
          columns: {
            name: "Nombre",
            description: "Descripción",
            actions: "Acciones",
          },
          searchFields: {
            companyName: "Nombre de la companía",
            categoryName: "Nombre de la categoría",
          },
        },
        fields: {
          name: {
            label: "Nombre",
            description: "El nombre de la categoría es obligatorio.",
            placeholder: "Nombre de la categoría",
          },
          description: {
            label: "Descripción",
            description: "La descripción es obligatoria.",
            placeholder: "Descripción de la categoría",
          },
          company: {
            label: "Compania",
            description: "La companía es obligatoria.",
            placeholder: "Elija una companía",
          },
        },
      },
      companies: {
        title: "Companía",
        detailsTitle: "Información de la companía",
        companyImages: "Imagenes de la compañía",
        grid: {
          title: "Companias listadas",
          newBtn: "Nueva Companía",
          columns: {
            name: "Nombre",
            description: "Descripción",
            website: "Sitio Web",
            actions: "Acciones",
          },
          searchFields: {
            companyName: "Filtrar por nombre de la companía",
          },
        },
        fields: {
          companyName: {
            label: "Nombre",
            description:
              "El nombre es obligatorio y se recomienda que sea único.",
          },
          companyDescription: {
            label: "Descripción",
            description:
              "Se recomienda el uso de una descripción, ya que aparecerá en la app del consumidor.",
          },
          website: {
            label: "Sitio Web",
            description:
              "Se recomienda el uso de un sitio web, ya que aparecerá en la app del consumidor.",
          },
          instagram: {
            label: "Instagram",
            description:
                "Se recomienda el uso de instagram, ya que aparecerá en la app del consumidor.",
        },
        facebook: {
            label: "Facebook ",
            description:
                "Se recomienda el uso de facebook, ya que aparecerá en la app del consumidor.",
        },
        linkedin: {
            label: "Linkedin ",
            description:
                "Se recomienda el uso de linkedin, ya que aparecerá en la app del consumidor.",
        },
          stelvioId: {
            label: "ID Stelviotech",
            description: "El ID de la companía en la plataforma.",
          },
          subdomain: {
            label: "Subdominio",
            description: "Subdominio de la companía",
        },
        externalid: {
            label: "Id externo",
            description: "Id externo de la compañia",
        },
        },
      },
      facilities: {
        title: "Instalaciones",
        detailsTitle: "Información de las Instalaciones",
        grid: {
          title: "Instalaciones listadas",
          newBtn: "Nueva Instalación",
          columns: {
            name: "Nombre",
            company: "Companía",
            address: "Dirección",
            zip: "CP",
            actions: "Acciones",
          },
          searchFields: {
            companyName: "Filtrar por nombre de companía",
            address: "Filtrar por dirección",
          },
        },
        fields: {
          name: {
            label: "Nombre",
            description: "El nombre de la instalación es obligatorio.",
            placeholder: "",
          },
          company: {
            label: "Companía",
            description: "La companía es obligatoria.",
            placeholder: "",
          },
          address: {
            label: "Dirección",
            description: "La dirección de la instalación es obligatoria.",
            placeholder: "Dirección de las instalaciones",
          },
          country: {
            label: "País",
            description: "El país es obligatorio.",
            placeholder: "",
          },
          zip: {
            label: "ZIP/CP",
            description: "El código ZIP/CP es obligatorio.",
            placeholder: "Código ZIP/Postal",
          },
        latitude: {
            label: "Latitud",
            description: "Latitud de la instalación.",
            placeholder: "",
        },
          externalId: {
            label: "Id externo",
            description: "Id externo.",
            placeholder: "",
          },
        longitude: {
            label: "Longitud",
            description: "Longitud de la instalación.",
            placeholder: "",
        },
        facilityType: {
          label: "Tipo de Instalación",
          description: "Tipo de instalación.",
          placeholder: "Elija un tipo de instalación",
          options: {
              CompanyFacility: "Instalacion de Companía",
              Farm: "Granja",
              Customer: "Cliente",
          },
      },
        },
      },
      members: {
        title: "Miembros",
        detailsTitle: "Información del miembro",
        grid: {
          title: "Miembros listados",
          newBtn: "Nuevo Miembro",
          columns: {
            member: "Miembro",
            company: "Companía",
            role: "Rol",
            inProcess: "En Proceso",
            enabled: "Habilitado",
            contact: "Contacto",
            actions: "Acciones",
          },
          searchFields: {
            companyName: "Filtrar por nombre de la companía",
            firstName: "Filtrar por nombre",
            lastName: "Filtrar por apellido",
            email: "Filtrar por email",
          },
        },
        fields: {
          firstName: {
            label: "Nombre",
            description: "El nombre es oblitagorio. ",
            placeholder: "Juan",
          },
          lastName: {
            label: "Apellido",
            description: "El apellido es obligatorio.",
            placeholder: "Perez",
          },
          company: {
            label: "Companía",
            description:
              "La companía a la que pertenece el miembro, es obligatoria.",
            placeholder: "Elija una companía",
          },
          userRole: {
            label: "Rol",
            description: "El rol de usuario es obligatorio.",
            placeholder: "Elija un rol de usuario",
          },
          password: {
            label: "Contraseña",
            description: "La contraseña es obligatoria.",
            placeholder: "abc123",
          },
          passwordConfirmation: {
            label: "Confirm Password",
            description: "Las contraseñas deben coincidir.",
            placeholder: "abc123",
          },
          gender: {
            label: "Género",
            description: "Género del miembro.",
            placeholder: "Elija un género",
          },
          dob: {
            label: "Fecha de nacimiento",
            description: "Fecha de nacimiento del miembro.",
            placeholder: "00/00/0000",
          },
          status: {
            label: "Estado",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
            description: "",
            placeholder: "",
          },
          address: {
            label: "Dirección",
            description: "Dirección del miembro.",
            placeholder: "Calle 123",
          },
          email: {
            label: "Email de contacto",
            description: "Sólo el miembro puede cambiar su email.",
            placeholder: "your@mail.net",
          },
          phone: {
            label: "Teléfono de contacto",
            description:
              "Sólo el miembro puede cambiar el teléfono de contacto.",
            placeholder: "1234567890",
          },
          username: {
            label: "Usuario",
            description:
                "Nombre de usuario para iniciar sesión",
            placeholder: "Nombre de usuario",
          },
        },
      },
      workflows: {
        title: "Fujos de Trabajo",
        detailsTitle: "Información del Flujo de Trabajo",
        settingsTitle: "Configuración del Flujo de Trabajo",
        grid: {
          title: "Flujos de Trabajo listados",
          newBtn: "Nuevo Flujo de Trabajo",
          columns: {
            name: "Nombre",
            steps: "Pasos",
            description: "Descripción",
            status: "Estado",
            actions: "Acciones",
          },
          searchFields: {
            companyName: "Nombre de la companía",
            name: "Nombre del Flujo de Trabajo",
            description: "Descripción del Flujo de Trabajo",
          },
        },
        fields: {
          name: {
            label: "Nombre",
            description: "El nombre del Flujo de Trabajo es obligatorio.",
            placeholder: "Nombre",
          },
          description: {
            label: "Descripción",
            description: "The workflow description is required.",
            placeholder: "Workflow description",
          },
          product: {
            label: "Producto",
            description: "Producto del Flujo de Trabajo.",
            placeholder: "Elija un producto",
          },
          stelvioId: {
            label: "ID Stelviotech",
            description: "ID del Flujo de Trabajo en la plataforma.",
            placeholder: "",
          },
          status: {
            label: "Estado",
            description:
              "Establece el estado del flujo de trabajo. Si está desactivado, se frenan las tareas.",
            placeholder: "",
            options: {
              active: "Activo",
              inactive: "Inactivo",
            },
          },
          type: {
            label: "Tipo de Flujo de Trabajo",
            description:
              'Un "flujo de trabajo continuado" está encadenado a otro flujo de trabajo que continúa el procesamiento de una tarea.',
            placeholder: "",
            options: {
              regular: "Regular",
              continued: "Continuado",
            },
          },
          nextWorkflow: {
            label: "Siguiente Flujo de Trabajo",
            description:
              "Following workflow must be selected por continued workflows.",
            placeholder: "Elija el siguiente flujo de trabajo",
            btnNew: "Nuevo Flujo de Trabajo",
          },
        },
        steps: {
          title: "Pasos del Flujo de Trabajo",
          newBtn: "Nuevo Paso",
          errors: {
            noSteps: "El flujo de trabajo no tiene pasos asignados.",
          },
          assigned: {
            title: "Pasos asignados",
          },
          modal: {
            title: "Información del Paso",
            fieldsTitle: "Elementos de formulario para este paso",
            newBtn: "Nuevo elemento",
            fields: {
              name: {
                label: "Nombre del paso",
                description:
                  "El nombre es obligatorio y se recomienda que sea único.",
                placeholder: "Nombre del paso",
              },
              description: {
                label: "Descripción",
                description: "La descripción es obligatoria.",
                placeholder: "Descripción del paso",
              },
              responsibles: {
                label: "Responsable/s",
                description:
                  "Elina los miembros que serán responsablees por este paso.",
                placeholder: "Elija responsable/s",
              },
              mandatory: {
                label: "Obligatorio",
                description: "",
                placeholder: "",
              },
            },
            stepField: {
              deleteBtn: "Eliminar elemento",
              fieldTypes: {
                TEXT: "Texto",
                NUMBER: "Número",
                DATE: "Fecha",
                TIMESTAMP: "Fecha y Hora",
                SELECT: "Combo",
                LOCATION: "Ubicación",
                DOCUMENT: "Documento",
                IMAGE: "Imagen",
                UNIT: "Unidades",
              },
              fields: {
                name: {
                  label: "Nombre",
                  description: "",
                  placeholder: "Nombre del elemento",
                },
                description: {
                  label: "Descripción",
                  description: "",
                  placeholder: "Descripción del elemento",
                },
                type: {
                  label: "Tipo de elemento",
                  description: "",
                  placeholder: "Tipo de elemento",
                },
              },
              dynamicSelect: {
                title: "Opciones",
                label: "Etiqueta",
                value: "Valor",
              },
              options: {
                mandatory: "Obligatorio",
                primary: "Primario",
                save: "Guardar",
                automatic: "Automático",
                mapIn: "Map in",
                mapOut: "Map out",
              },
            },
          },
        },
      },
      common: {
        forms: {
          comboBoxes: {
            pickCompany: "Elija una companía",
            pickCategory: "Elija una categoría",
          },
          errors: {
            checkFields: "Revise todos los campos obligatorios.",
            unsavedChanges: "Cuidado! Hay cambios pendientes de guardar.",
          },
          buttons: {
            applyFilters: "Aplicar flitros",
            saveAndClose: "Guardar & Cerrar",
            close: "Cerrar",
            cancel: "Cancelar",
          },
        },
        buttons: {
          edit: "Editar",
          view: "Ver",
          viewOrEdit: "Ver/Editar",
          remove: "Quitar",
          active: "Activo",
          inactive: "Inactivo",
          search: "Buscar",
          viewAll: "Ver todo",
          duplicate: "Duplicar",
        },
        errors: {},
      },
    },
    TRANSLATOR: {
      SELECT: "Elige tu idioma",
    },
    MENU: {
      NEW: "nuevo",
      MAIN: "Menú principal",
      ACTIONS: "Comportamiento",
      CREATE_POST: "Crear nueva publicación",
      PAGES: "Pages",
      FEATURES: "Caracteristicas",
      APPS: "Aplicaciones",
      DASHBOARD: "Tablero",
      PRODUCTS: "Productos",
      CATEGORIES: "Categorías de Producto",
      WORKFLOWS: "Flujos de Trabajo",
      COMPANIES: "Companías",
      FACILITIES: "Instalaciones",
      ACTIVITY: "Actividades",
      MEMBERS: "Miembros",
      TRACKING: "Seguimiento",
      SETTINGS: "Configuración",
      SUST_PER_BATCH: "Sus. per batch",
      PROFILE: "Mi Perfil",
      PROJECTS: "Proyectos",
      REGENERATIVE_FINANCE: "Finanzas regenerativas",
      MY_ASSETS: "Mis activos",
      TEAM: "Equipo",
      CONFIGURATION: "Configuración",
      SUPPLY_CHAIN: "Supply chain"


    },
    AUTH: {
      GENERAL: {
        OR: "O",
        SUBMIT_BUTTON: "Enviar",
        NO_ACCOUNT: "No tienes una cuenta?",
        SIGNUP_BUTTON: "Regístrate",
        FORGOT_BUTTON: "Se te olvidó tu contraseña",
        BACK_BUTTON: "Espalda",
        PRIVACY: "Intimidad",
        LEGAL: "Legal",
        CONTACT: "Contacto",
      },
      LOGIN: {
        TITLE: "Crear una cuenta",
        BUTTON: "Registrarse",
      },
      FORGOT: {
        TITLE: "Contraseña olvidada?",
        DESC: "Ingrese su correo electrónico para restablecer su contraseña",
        SUCCESS: "Your account has been successfully reset.",
      },
      REGISTER: {
        TITLE: "Sign Up",
        DESC: "Enter your details to create your account",
        SUCCESS: "Your account has been successfuly registered.",
      },
      INPUT: {
        EMAIL: "Email",
        FULLNAME: "Fullname",
        PASSWORD: "Password",
        CONFIRM_PASSWORD: "Confirm Password",
        USERNAME: "Usuario",
      },
      VALIDATION: {
        INVALID: "{{name}} is not valid",
        REQUIRED: "{{name}} is required",
        MIN_LENGTH: "{{name}} minimum length is {{min}}",
        AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
        NOT_FOUND: "The requested {{name}} is not found",
        INVALID_LOGIN: "The login detail is incorrect",
        REQUIRED_FIELD: "Required field",
        MIN_LENGTH_FIELD: "Minimum field length:",
        MAX_LENGTH_FIELD: "Maximum field length:",
        INVALID_FIELD: "Field is not valid",
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Selected records count: ",
        ALL: "All",
        SUSPENDED: "Suspended",
        ACTIVE: "Active",
        FILTER: "Filter",
        BY_STATUS: "by Status",
        BY_TYPE: "by Type",
        BUSINESS: "Business",
        INDIVIDUAL: "Individual",
        SEARCH: "Search",
        IN_ALL_FIELDS: "in all fields",
      },
      ECOMMERCE: "eCommerce",
      CUSTOMERS: {
        CUSTOMERS: "Customers",
        CUSTOMERS_LIST: "Customers list",
        NEW_CUSTOMER: "New Customer",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Customer Delete",
          DESCRIPTION: "Are you sure to permanently delete this customer?",
          WAIT_DESCRIPTION: "Customer is deleting...",
          MESSAGE: "Customer has been deleted",
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Customers Delete",
          DESCRIPTION: "Are you sure to permanently delete selected customers?",
          WAIT_DESCRIPTION: "Customers are deleting...",
          MESSAGE: "Selected customers have been deleted",
        },
        UPDATE_STATUS: {
          TITLE: "Status has been updated for selected customers",
          MESSAGE: "Selected customers status have successfully been updated",
        },
        EDIT: {
          UPDATE_MESSAGE: "Customer has been updated",
          ADD_MESSAGE: "Customer has been created",
        },
      },
    },
  },
};
