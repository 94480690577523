<svg viewBox="0 0 10 8" width="100%" height="320">
    <defs>
        <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color:#6bab3b;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#a9eb7e;stop-opacity:1" />
        </linearGradient>
      </defs>
      <path d="M2 7.5 A 4 4 0 1 1 8 7.5" fill="none" stroke-width="0.78" stroke="#B0B0B0" stroke-linecap="round" />
      <path #loaderPath class="loader" d="M2 7.5 A 4 4 0 1 1 8 7.5" fill="none" stroke-width="0.8" stroke="url(#strokeGradient)" stroke-linecap="round" />
      <text x="5" y="3.5" text-anchor="middle" font-size="0.8" fill="#000">90% </text>
    <text x="5" y="5" text-anchor="middle" font-size="0.5" fill="#000">de sostenibilidad </text>
  </svg>