import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-total-progress-card',
    templateUrl: './total-progress-card.component.html',
    styleUrls: ['./total-progress-card.component.scss']
})
export class TotalProgressCardComponent implements OnInit {
    @Input('color') color: string;
    @Input('title') title: string;
    @Input('total') total: number;
    @Input('current') current: number;

    constructor() {
    }

    ngOnInit(): void {
    }

}
