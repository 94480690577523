import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable()
export class ImagesDocumentsRequest {
    private apiRoot: string = `${environment.apiUrl}/workflow/activity/`;

    constructor(private http: HttpClient) {
    }

    getImage(id: string) {
        return this.http.get(`${this.apiRoot}image/${id}/`);
    }

    getDocument(id: string) {
        return this.http.get(`${this.apiRoot}document/${id}/`);
    }
}
