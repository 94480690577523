import {Injectable} from "@angular/core";
import {BatchsRequest} from "../requests/batchs.request";

@Injectable()
export class BatchsFacade {
    constructor(private request: BatchsRequest){}

    get(batchId: string) {
        return this.request.get(batchId);
    }

    getLocator(batchId: string) {
        return this.request.getLocator(batchId);
    }
}