import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable()
export class MessagesRequest {
    private apiRoot: string = `${environment.apiUrl}/messages`;
    constructor(private http: HttpClient) {
    }

    get(messageId: string) {
        return this.http.get(`${this.apiRoot}/${messageId}`);
    }

    getUserMessages(userId: string) {
        return this.http.get(`${this.apiRoot}/user/${userId}`);
    }
}