import { Injectable } from "@angular/core";
import { CommonRequest } from "../requests/common.request";

@Injectable()
export class CommonFacade {
 
  constructor(private request: CommonRequest) {}

  getTemplates() {
    // return [

    //   {
    //     id:1,
    //     name:"green-template"
    //   },
    //   {
    //     id:2,
    //     name:"gold-template"
    //   },
    //   {
    //     id:3,
    //     name:"default-template"
    //   }
    // ]
    return this.request.getTemplates();
  }

}
