import {Component, Input} from "@angular/core";
import {WorkflowListItemModel} from "../../../members/models/workflow-list-item.model";

@Component({
    selector: 'stelvio-workflows-list-item',
    templateUrl: './workflow-list-item.component.html',
    styleUrls: ['./workflow-list-item.component.scss']
})
export class WorkflowListItemComponent {
    @Input() settings: WorkflowListItemModel;

    constructor() {
    }
}