import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {LogEntryModel} from "../models/log-entry.model";

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    public logs: BehaviorSubject<LogEntryModel[]> = new BehaviorSubject<LogEntryModel[]>([]);

    constructor() {
    }

    addLog(log: LogEntryModel) {
        const logs = this.logs.getValue();
        this.logs.next( logs.concat(log) );
    }
}