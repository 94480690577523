import { Component, Input,OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-tracking',
  templateUrl: './modal-tracking.component.html',
  styleUrls: ['./modal-tracking.component.scss']
})
export class ModalTrackingComponent implements OnInit {
  @Input() content: HTMLElement;
  ref: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  fireAction(action: string, reason?: any) {
    switch (action) {
      case "close":
        this.ref.close(reason);
        break;
      case "dismiss":
        this.modalService.dismissAll(reason);
        break;
    }
  }
}
