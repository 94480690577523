<div class="w-100 h-100">
    <div class="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end  mb-5 mb-xl-10 h-100" style="background-color: #0A3400;background-image:url('assets/media/patterns/vector-1.png')">
        <!--begin::Header-->
        <div class="card-header pt-5">
            <!--begin::Title-->
            <div class="card-title d-flex flex-column">
                <!--begin::Amount-->
                <span class="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{{current}}</span>
                <!--end::Amount-->
                <!--begin::Subtitle-->
                <span class="text-white opacity-75 pt-1 fw-semibold fs-6">{{title}}</span>
                <!--end::Subtitle-->
            </div>
            <!--end::Title-->
        </div>
        <!--end::Header-->
        <!--begin::Card body-->
        <div class="card-body d-flex align-items-end pt-0">
            <!--begin::Progress-->
            <div class="d-flex align-items-center flex-column mt-3 w-100">
                <div class="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2">
                    <span>{{total}} Pending</span>
                    <span>{{total>0 ? ((total*100)/current).toFixed(2) : 0}}%</span>
                </div>
                <div class="h-8px mx-3 w-100 bg-white bg-opacity-50 rounded">
                    <div class="bg-white rounded h-8px" role="progressbar" style="max-width:100%;width: {{total ? (total*100)/current : 0}}%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <!--end::Progress-->
        </div>
        <!--end::Card body-->
    </div>
</div>

