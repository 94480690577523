import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../auth";
import {MembersFacade} from "../members/services/members.facade";
import {Observable} from "rxjs";
import {DashboardService} from "../../pages/services/dashboard.service";
import {Router} from "@angular/router";

export interface ActivityRecord {
    id: number;
    title: string;
    value: string;
  }

  export interface Product {
    id: number;
    title: string;
    value: string;
  }

  export interface Workflow {
    id: number;
    title: string;
    value: string;
  }
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    totalWorkflows: number = 0;
    totalActiveWorkflows: number = 0;
    totalProducts: number = 0;
    totalActiveProducts: number = 0;
    deliveriedBatches: number = 0;
    totalDeliveriedBatches: number = 0;
    stepHistogramData: any = [];
    totalMembers: number = 0;
    totalFacilities: number = 0;
    pendingActivities: any = [];
    totalPendingActivities: number = 0;
    lastCompletedActivities: any = [];
    workflowsDataChart: any = []
    productsDataChart: any = []
    totalActivities:number = 0;
    activityRecords: ActivityRecord[] = [
        {
          id: 1,
          title: 'Slaughter',
          value: '62'
        },
        {
          id: 2,
          title: 'Cutting Plant',
          value: '100'
        },
        {
          id: 3,
          title: 'Salting',
          value: '100'
        }
      ];
    workflows:Workflow[] = [
        {
            id: 1,
            title: 'Jamon Duroc DO Teruel - WKFL ',
            value: '62,5'
          },
          {
            id: 2,
            title: 'Chuleta Duroc DO Teruel - WKFL',
            value: '89,5'
          }
        ];
    products:Product[] =[
        {
            id: 1,
            title: 'Jamon Duroc DO Teruel',
            value: '62'
          },
          {
            id: 2,
            title: 'Codillo',
            value: '87,87'
          }
 ,
          {
            id: 3,
            title: 'Cerdo Blanco Duroc',
            value: '100'
          }
        ];
    dashboardData$: Observable<any>;
    showSustainabilitySection:boolean= false;
    constructor(private authService: AuthService,
                private memberService: MembersFacade,
                private dashboardFacade: DashboardService,
                private router: Router,private cdRef: ChangeDetectorRef) {

    }

    ngOnInit(): void {
        const user: any = this.authService.currentUserValue;
        if (user.hasOwnProperty('id')) {
            this.dashboardData$ = this.memberService.dashboard(user.id);
        }
        this.initDashboard()
        
       /* setTimeout(()=>{
            this.cdRef.detectChanges()
        },1000)*/
    }

    async initDashboard() {
        await this.getTotalWorkflows()
        await this.getActiveWorkflows()
        await this.getProducts()
        this.getActiveProducts()
        this.getBatchId()
        this.stepHistogram()
        this.getTotalMembers()
        this.getPendingActivities()
        this.getLastCompletedActivities()

    }

    getTotalWorkflows() {
        return this.dashboardFacade.getTotalWorkflows().subscribe((total: any) => {
            this.totalWorkflows = total
            return total
        })
    }

    getActiveWorkflows() {
        return this.dashboardFacade.getActiveWorkflows().subscribe((wfs: any) => {
            this.totalActiveWorkflows = wfs
            this.buildDonutDataWf()
            return wfs
        })
    }

    getProducts() {
        return this.dashboardFacade.getTotalProducts().subscribe((wfs: any) => {
            this.totalProducts = wfs
            return wfs
        })
    }

    getActiveProducts() {
        return this.dashboardFacade.getActiveProducts().subscribe((wfs: any) => {
            this.totalActiveProducts = wfs
            this.buildDonutDataProduct()
            return wfs
        })
    }

    getBatchId() {
        return this.dashboardFacade.getDeliveriedBatches().subscribe((wfs: any) => {
            this.totalDeliveriedBatches = wfs
            return wfs
        })
    }

    stepHistogram() {
        return this.dashboardFacade.getStepHistogram().subscribe((stp: any) => {
            this.stepHistogramData = stp
            return stp
        })
    }

    getPendingActivities() {
        return this.dashboardFacade.getPendingActivities().subscribe((stp: any) => {
            this.pendingActivities = stp
            this.totalPendingActivities = stp.length
            return stp
        })
    }

    getLastCompletedActivities() {
        return this.dashboardFacade.lastCompletedActivities().subscribe((stp: any) => {
            this.lastCompletedActivities = stp
            this.cdRef.detectChanges()
            return stp
        })
    }  getTotalMembers() {
        return this.dashboardFacade.getTotalMembers().subscribe((stp: any) => {
            this.totalMembers = stp
            return stp
        })
    }

    buildDonutDataWf() {
        console.log(this.totalActiveWorkflows)
        console.log(this.totalActiveWorkflows.toFixed(2))
        this.workflowsDataChart.series = [this.totalActiveWorkflows, this.totalWorkflows]
        this.workflowsDataChart.labels = ['Active Workflows', 'Total Workflows']
    }

    buildDonutDataProduct() {
        this.productsDataChart.series = [this.totalActiveProducts, this.totalProducts]
        this.productsDataChart.labels = ['Active Products', 'Total products']
    }

    goSustainability(){
        this.handleSustainabilityScore();
    }
    handleSustainabilityScore(){
        this.showSustainabilitySection = !this.showSustainabilitySection
    }
}
