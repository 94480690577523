import {Component, Input, OnInit} from '@angular/core';
import {sha1} from "@angular/compiler/src/i18n/digest";

@Component({
    selector: 'app-responsibles-avatar',
    templateUrl: './responsibles-avatar.component.html',
    styleUrls: ['./responsibles-avatar.component.scss']
})
export class ResponsiblesAvatarComponent implements OnInit {

    @Input('data') responsibles: any;
    public circleColor: any = [];
    avatarCreated = false
    avatarsCreated: any = []
    private colors = [
        '#3498db', // Azul
        '#2ecc71', // Verde esmeralda
        '#e74c3c', // Rojo
        '#f39c12', // Naranja
        '#9b59b6', // Morado
        '#1abc9c', // Turquesa
        '#d35400', // Zanahoria
        '#2980b9', // Azul oscuro
        '#27ae60', // Verde oscuro
        '#c0392b', // Rojo oscuro
    ];

    constructor() {
    }

    ngOnInit(): void {
    }

    private createInititals(name: string, index: number, responsible_id: number): string {
        let storageColorsJson = this.getCurrentColoresSetted()
        const currentResponsibleColor = storageColorsJson ? storageColorsJson.find((item: any) => {
            return item.id == responsible_id
        }) : []
        this.asignColorResponsible(currentResponsibleColor, storageColorsJson, responsible_id)

        // Dividir la cadena en palabras
        var palabras = name.split(" ");
        var firstChar = palabras[0].substring(0, 1).toUpperCase();
        var secondChar = palabras[1].substring(0, 1).toUpperCase();
        let initials = firstChar + secondChar;
        return initials
    }

    getInitials(responsible: any, size: any, index: number) {
        if (!this.avatarCreated) {
            let initials = ''
            if (responsible.label) {
                initials = this.createInititals(responsible.label, index, responsible.id)
            } else {
                const name = responsible.firstName + ' ' + responsible.lastName
                initials = this.createInititals(name, index, responsible.id)

            }
            this.avatarsCreated.push(initials)
            if (this.avatarsCreated.length == size) {
                this.avatarCreated = true
            }
            return initials
        } else {
            return this.avatarsCreated[index]
        }
    }

    asignColorResponsible(currentResponsibleColor: any, storageColorsJson: Array<object>, responsible_id: number) {
        if (currentResponsibleColor) {
            this.circleColor.push(currentResponsibleColor.color);
        } else {
            let randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
            storageColorsJson.push({id: responsible_id, color: this.colors[randomIndex]})
            this.circleColor.push(this.colors[randomIndex]);
            this.colors.splice(randomIndex, 1)
        }
        this.saveColorsToStorage(storageColorsJson)
    }

    getCurrentColoresSetted() {
        let storageSettedColors: any = localStorage.getItem('resp-colors-setted'); /* [ { "id":18,"color": "#44444"} ] */
        let storageColorsJson = storageSettedColors ? JSON.parse(this.decryptString(storageSettedColors)) : []
        return storageColorsJson
    }

    saveColorsToStorage(storageColorsJson: Array<object>) {
        const finalColorsJson = JSON.stringify(storageColorsJson)
        localStorage.setItem('resp-colors-setted', this.encryptString(finalColorsJson))
    }
     encryptString(inputString:string) {
        return inputString.split('').map(char => String.fromCharCode(char.charCodeAt(0) + 1)).join('');
    }


     decryptString(encryptedString:string) {
        return encryptedString.split('').map(char => String.fromCharCode(char.charCodeAt(0) - 1)).join('');
    }
    getResponsibleFullName(responsible: any) {
        return responsible.firstName + ' ' + responsible.lastName + ' - ' + responsible.email
    }
}
