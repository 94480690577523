import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class BbtwinsPermissionsService {
    constructor(private http: HttpClient) {
    }

    public load() {
        // TODO -> Implementar permisos BE
        return of([]);
        /*return this.http.get('/api/permissions').pipe(
            map((response) => response as string[])
        );*/
    }
}