import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { WorkflowDataTypes, WorkflowStepDataModel } from '../models/workflow-step.model'
import { PaginationModel } from '../models/pagination.model'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'
import { ReportsRequest } from '../requests/reports.request'

@Injectable()
export class ReportsFacade {
	private defaultPagination: PaginationModel = {
		last: false,
		pageNumber: 0,
		pageSize: environment.pagination.pageSize,
		totalPages: 0,
		totalElements: 0,
	}
	private defaultPic = '../../../../assets/media/avatars/company-generic.png'

	constructor(private request: ReportsRequest) {}

	query(pagination: PaginationModel = this.defaultPagination, filters?: any) {
		return this.request.query(pagination, filters).pipe(
			map((result: any) => {
				return {
					...result,
					content: result.content.map((framework: any) => framework),
				}
			}),
		)
	}

	get(frameworkId: string): Observable<any> {
		return this.request.get(frameworkId)
	}

	create(framework: any) {
		return this.request.create(framework)
	}

	update(framework: any) {
		return this.request.update(framework)
	}

	delete(frameworkId: string | number) {
		return this.request.delete(frameworkId)
	}

	parseVisibility(item: any) {
		if (item._public) {
			return 'public'
		}
		if (item._private) {
			return 'private'
		}
		if (item.viewer) {
			return 'viewer'
		}
	}

	hasActivityPending(workflowId: string | number) {
		return this.request.hasActivityPending(workflowId)
	}
}
