import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable()
export class DashboardRequest {
    private apiRoot: string = `${environment.apiUrl}/dashboard/`;

    constructor(private http: HttpClient) {
    }

    totalWorkflows(){
        return this.http.get(`${this.apiRoot}workflows/count`);
    }
    totalActiveWorkflows(){
        return this.http.get(`${this.apiRoot}workflows/count/active`);
    }
    totalProducts(){
        return this.http.get(`${this.apiRoot}products/count`);

    }
    totalActiveProducts(){
        return this.http.get(`${this.apiRoot}products/count/active`);
    }
    deliveriedBatches(){
        return this.http.get(`${this.apiRoot}delivered-batches/count`);
    }
    getStepHistogram(){
        return this.http.get(`${this.apiRoot}step-histogram`);
    }
    getPendingActivities(){
        return this.http.get(`${this.apiRoot}pending-activities/info`);
    }
    lastCompletedActivities(){
        return this.http.get(`${this.apiRoot}activities/info`);
    }getTotalMembers(){
        return this.http.get(`${this.apiRoot}members/count`);
    }
    query(filters?: any) {
        return [2]
    }


}
