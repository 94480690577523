import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {CompanyModel} from "../models";
import {PaginationModel} from "../models/pagination.model";
import {InvitationRequestModel, NewCompanyInvitationModel} from "../models/company.model";

@Injectable()
export class CompaniesRequest {
    private apiRoot: string = `${environment.apiUrl}`;

    constructor(private http: HttpClient) {
    }

    query(pagination: PaginationModel, params?: any) {
        return this.http.get(`${this.apiRoot}/company/`, {
            params: {
                size: pagination.pageSize,
                page: pagination.pageNumber,
                ...params
            }
        });
    }

    get(id: string) {
        return this.http.get(`${this.apiRoot}/company/${id}/`);
    }

    update(company: CompanyModel) {
        return this.http.put(`${this.apiRoot}/company/`, company);
    }

    create(company: CompanyModel) {
        return this.http.post(`${this.apiRoot}/company/`, company);
    }

    sendInvitationRequest(invitation: InvitationRequestModel) {
        return this.http.post(`${this.apiRoot}/company/relationship`, invitation);
    }

    sendInvitationNewCompany(invitation: NewCompanyInvitationModel) {
        return this.http.post(`${this.apiRoot}/company/relationship/new`, invitation);
    }

    remove(id: string | number) {
        return this.http.delete(`${this.apiRoot}/company/${id}`);
    }

    handleInvitation(invitationId: string | number,action:string) {
        return this.http.post(`${this.apiRoot}/company/relationship/${invitationId}/${action}`,undefined);
    }

    all() {
        return this.http.get(`${this.apiRoot}/company/short/all`)
    }

    uploadLogo(image: any, companyId: number) {
        const formData: FormData = new FormData();
        formData.set('image', image, image.name);
        formData.set('idCompany', companyId + '');
        return this.http.post(`${this.apiRoot}/company/logo/`, formData)
    }

    getImages(companyId: number) {
        return this.http.get(`${this.apiRoot}/company/images`)

    }

    uploadImages(images: any, companyId: number) {
        const formData: FormData = new FormData();
        for (let i = 0; i < images.length; i++) {
            if (images[i].id > 0) {
                formData.append('ids', images[i].id);
                if(images[i].image){
                    formData.append('images', images[i].image ? images[i].image : null, images[i].image.name ? images[i].image.name : null);
                    formData.append('imageNames',images[i].image != null && images[i].image.name ? images[i].image.name : null);
                }else{
                    formData.append('images', "null");
                    formData.append('imageNames',images[i].image != null && images[i].image.name ? images[i].image.name : null);

                }
            } else {
                if(images[i].image){
                    formData.append('images', images[i].image ? images[i].image : null, images[i].image.name ? images[i].image.name : null);
                    formData.append('imageNames',images[i].image != null && images[i].image.name ? images[i].image.name : null);

                }else{
                    formData.append('images', "null");
                    formData.append('imageNames',images[i].image != null && images[i].image.name ? images[i].image.name : null);
                }
            }
            formData.append('titles', images[i].title);
            formData.append('texts', images[i].text ? images[i].text : null);
            formData.append('tags', images[i].tag);
            formData.append('videoLinks', images[i].videoLink ? images[i].videoLink : null);
        }
        formData.append('idCompany', companyId + '');
        return this.http.post(`${this.apiRoot}/company/images/`, formData)
    }

    deleteImage(imageId: number) {
        return this.http.delete(`${this.apiRoot}/company/images/${imageId}`)
    }
}