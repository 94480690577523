<!-- begin::Row -->
<div class="card shadow-sm mb-3" style="background: rgba(240,237,213,0)">
    <div class="card-body row">
        <div class="col-12 ">
            <h1 class="page-title">Dashboard</h1>
        </div>
        <div class="row mt-2 total-cards-dashboard ">
            <img src="/assets/media/dashboard/dash1-2.png" alt="">
        </div>
            <div class="row col-12">
              <img src="/assets/media/dashboard/dash2.png" alt="">
            </div>
            <div class="row col-12">
              <img src="/assets/media/dashboard/dash3.png" alt="">

            </div>
            <div class="row col-12">
              <img src="/assets/media/dashboard/dash4-1.png" alt="">
            </div>
            <div class="row col-12">
              <img src="/assets/media/dashboard/dash5.png" alt="">

            </div>
    </div>
</div>
