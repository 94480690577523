import { Component, ElementRef, Input,  OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number = 0; // Valor de progreso de 0 a 100
  @ViewChild('loaderPath', { static: true }) loaderPath!: ElementRef<SVGPathElement>;

  constructor() { }

  ngOnInit(): void {
    console.log(this.progress)
    this.updateProgress(this.progress);
  }
  private updateProgress(percentage: number): void {
    const path = this.loaderPath.nativeElement;
    const length = path.getTotalLength();

    // stroke-dasharray establece la longitud del trazo
    path.style.strokeDasharray = `${length}`;

    // stroke-dashoffset determina cuánto del trazo es visible
    path.style.strokeDashoffset = `${length - (percentage / 100 * length)}`;
  }
}
