import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef
} from "@angular/core";
import {StelvioGridSettingsModel} from "./stelvio-grid-settings.model";
import {Observable} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: "stelvio-grid",
    templateUrl: "./stelvio-grid.component.html",
    styleUrls: [
        "./stelvio-grid.component.scss",
        "../../../../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css",
    ],
})
export class StelvioGridComponent implements OnInit, OnChanges {
    @Input() settings: StelvioGridSettingsModel;
    currentSettings: any;
    @Input() data: Observable<any[]>;
    @Input() message_no_data:string = "";
    @Output() onNew: EventEmitter<any> = new EventEmitter();
    @Output() onPage: EventEmitter<{ pageNumber: number }> = new EventEmitter<{
        pageNumber: number;
    }>();
    @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSort: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDuplicate: EventEmitter<any> = new EventEmitter<any>();
    fg: FormGroup = new FormGroup({
        search: new FormControl("", [Validators.required]),
        active: new FormControl("enabled", []),
    });
    pages: number[] = [];


    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.cdRef.detectChanges();
        this.currentSettings = changes.settings
    }

    ngOnInit() {
        this.currentSettings = this.settings


        if (!this.settings.filters?.filterByActive)
            this.fg.controls.active.setValue(null);

        if (this.settings.filters?.defaultValues) {
            const active = !!this.settings.filters.defaultValues?.active
                ? "enabled"
                : this.settings.filters.defaultValues?.active === false
                    ? "disabled"
                    : null;
            this.fg.controls.active.setValue(active);
            this.fg.controls.search.setValue(
                this.settings.filters.defaultValues.search
            );
        }

        for (let i = 0; i < this.settings.pagination.totalPages; i++) {
            this.pages.push(i);
        }
        this.updatePagination()

    }

    fireAction(event: { id: number; option: any; item: any }) {
        this.onAction.emit(event);
    }

    fireNewEvent() {
        this.onNew.emit(true);
    }

    updatePagination() {
        const totalPages = this.settings.pagination.totalPages;
        const currentPage = this.settings.pagination.pageNumber;
        const maxPagesToShow = 5; // Mostrar 5 páginas por rango

        this.pages = [];

        let startPage: number;
        let endPage: number;

        if (currentPage <= 2) {
            startPage = 0;
            endPage = Math.min(totalPages - 1, maxPagesToShow - 1);
        }
        else if (currentPage >= totalPages - 3) {
            startPage = Math.max(totalPages - maxPagesToShow, 0);
            endPage = totalPages - 1;
        }
        else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }

        for (let i = startPage; i <= endPage; i++) {
            this.pages.push(i);
        }

        if (startPage > 0) {
            this.pages.unshift(-1);
        }
        if (endPage < totalPages - 1) {
            this.pages.push(-2);
        }
    }



    firePage(page: number) {
        const totalPages = this.settings.pagination.totalPages;
        if (page >= 0 && page < totalPages) {
            this.settings.pagination.pageNumber = page;
            this.onPage.emit({pageNumber: page});
        }
        this.updatePagination();




    }
    adjustVisiblePages(direction: 'next' | 'prev') {
        const totalPages = this.settings.pagination.totalPages;
        let newPage: number;

        if (direction === 'next') {
            const lastVisiblePage = this.pages[this.pages.length - 2];
            newPage = Math.min(totalPages - 1, lastVisiblePage + 3);
            this.firePage(newPage);
        } else if (direction === 'prev') {
            const firstVisiblePage = this.pages[1];
            newPage = Math.max(0, firstVisiblePage - 3);
            this.firePage(newPage);
        }


    }
    fireSort(params: { field: string; order: string }) {
        if (!params.field) params.field = "id";
        if (!params.order) params.order = "desc";
        this.onSort.emit({sort: `${params.field}-${params.order}`});
    }

    fireSearch() {
        const params = this.fg.getRawValue();
        if (!this.settings.filters?.filterByActive) params.active = undefined;
        else params.active = params.active == "enabled";

        this.onSearch.emit(params);
    }
}
