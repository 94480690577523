import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription } from "rxjs";
import { map, catchError, switchMap, finalize } from "rxjs/operators";
import { UserModel } from "../models/user.model";
import { AuthModel } from "../models/auth.model";
import { AuthHTTPService } from "./auth-http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { ToastService } from "../../common/services";

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isLoggedIn: BehaviorSubject<boolean>;

  private authRefreshToken: string;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  get isUserLoggedIn(): boolean {
    return this.isLoggedIn.value;
  }

  set isUserLoggedIn(value: boolean) {
    this.isLoggedIn.next(value);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private toastService: ToastService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.isLoggedIn = new BehaviorSubject<boolean>(false);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        this.isUserLoggedIn = true;
        this.startRefreshTokenTimer();
        return result;
      }),
      switchMap(() => this.getUserByToken()),

      catchError((err) => {
        console.error("err", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    this.stopRefreshTokenTimer();
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(["/auth/login"], {
      queryParams: {},
    });
    this.toastService.hideToast();
  }

  unauthorizedRedirect() {
    this.router.navigate(["dashboard"], {
      queryParams: {
        error: 403,
      },
    });
  }

  isLogged() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return false;
    }

    return true;
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
          this.isUserLoggedIn = true;
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public userHasRole(role: string) {
    if (!role) return true;
    return !!this.getUserRoles().filter((r: string) => r === role).length;
  }

  public userHasRoles(roles: string[]) {
    return !!this.getUserRoles().find((value: string) => {
      return roles.includes(value);
    });
  }

  public getUserRoles() {
    const auth = this.getAuthToken();
    if (!auth) return [];
    const parsedToken = JSON.parse(atob(auth.authToken.split(".")[1]));
    return parsedToken.resource_access["bbtwins-api"].roles;
  }

  getAuthToken(): AuthModel | undefined {
    return this.getAuthFromLocalStorage();
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      // TODO -> Review this BE integration
      //switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error("err", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  private refreshToken() {
    return this.authHttpService.refreshToken(this.authRefreshToken).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        this.isUserLoggedIn = true;
        this.startRefreshTokenTimer();
        return result;
      }),
      switchMap(() => this.getUserByToken()),

      catchError((err) => {
        console.error("err", err);
        this.stopRefreshTokenTimer();
        this.router.navigate(["/auth/logout"]);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      this.authRefreshToken = auth.refreshToken || "";
      localStorage.setItem(
        this.authLocalStorageToken,
        JSON.stringify({ ...auth, refreshToken: undefined })
      );
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private refreshTokenTimeout: any;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const auth = this.getAuthFromLocalStorage();
    if (!auth) return false;
    const jwtToken = JSON.parse(atob(auth.authToken.split(".")[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    this.authRefreshToken = "";
    clearTimeout(this.refreshTokenTimeout);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  resetPassword(password: string,token:string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
        .resetPassword(password,token)
        .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  checkPassworkResetToken(token:string):Observable<boolean> {
    return this.authHttpService
        .checkPassworkResetToken(token)
        .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
}
