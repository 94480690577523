<a href="#" class="card h-100 bg-{{color}} hoverable card-xl-stretch mb-xl-8 ">
    <!--begin::Body-->
    <div class="card-body">
        <i class="fa fa-{{icon}} text-{{color_text}}-100 fs-2x ms-n1">

        </i>
        <div class="text-{{color_text}} fw-bold fs-2 mb-2 mt-5 h-100"><div >{{totalPending}} {{titlePending}}</div></div>
        <div class="col-auto fw-semibold text-{{color_text}}-100" *ngIf="!noSubtitle" >{{title}} {{total}}</div>
        <div class="col-auto fw-semibold text-white" *ngIf="noSubtitle">{{total}}</div>

    </div>
    <!--end::Body-->
</a>