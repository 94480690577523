<div
    #toastElement
    class="toast fade  toast-width mt-2"  data-bs-autohide="true"
    [ngClass]="type"
    role="alert"
    aria-live="polite"
    aria-atomic="true"
>
    <div class="toast-body">
       <div class="row w-100 align-items-center">
           <div class="col-1">
               <span class="icon-toast">
                <i class="mr-3  fa " [ngClass]="'fa-'+ (type=='error' || type=='warning'? 'warning': (type=='success'?'check-circle':(type=='info'?'exclamation-circle':'warning')))"
                    [style]="'color:'+ (type=='error'? '#cf000f': (type=='success'?'#009944':(type=='info'?'#63c0df':'#FADD90')))"
                ></i>
               </span>
           </div>
           <div class="col-10">
               <span [innerHtml]="message">
               </span>
           </div>
           <div class="col-1">
               <button type="button" class="btn " aria-label="Close" (click)="hide()">
                   <i class="fa fa-times-circle text-white "></i>
               </button>
           </div>
       </div>
       <div class="row w-100">
           <div class="notification__progress" [style]="'background: linear-gradient(to right,black,'+ (type=='error'? '#cf000f': (type=='success'?'#009944':(type=='info'?'#63c0df':'#FADD90'))) +')'"></div>
       </div>
    </div>
</div>
