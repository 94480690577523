import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import {InlineSVGModule} from "ng-inline-svg-2";
import {CommonStelvioModule} from "../common/common.module";
import {TranslationModule} from "../i18n";
import {DashboardService} from "../../pages/services/dashboard.service";
import {DashboardRequest} from "../../pages/requests/dashboard.request";
import { TotalDoughnutChartComponent } from './charts/total-doughnut-chart/total-doughnut-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TotalProgressCardComponent } from './partials/total-progress-card/total-progress-card.component';
import { TotalCardComponent } from './partials/total-card/total-card.component';
import { DashboardTableCardComponent } from './partials/dashboard-table-card/dashboard-table-card.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SustainabilityScoreDashboardComponent } from './partials/sustainability-score-dashboard/sustainability-score-dashboard.component';

@NgModule({
  declarations: [DashboardComponent, TotalDoughnutChartComponent, TotalProgressCardComponent, TotalCardComponent, DashboardTableCardComponent, ProgressBarComponent, SustainabilityScoreDashboardComponent],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                component: DashboardComponent,
            },
        ]),
        InlineSVGModule,
        CommonStelvioModule,
        TranslationModule,
        NgApexchartsModule
    ],
    providers:[DashboardService,DashboardRequest]

})
export class DashboardModule {}
