import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {ApexChart, ApexNonAxisChartSeries, ApexResponsive, ChartComponent} from "ng-apexcharts";

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
};

@Component({
    selector: 'stelvio-pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
    @ViewChild('chart') chart: ChartComponent;
    @Input() settings: any;
    public chartOptions: any;
    private colors: string[] = ['#EDF3F7', '#B2BE24', '#F0EDD5', '#FBEEE8', '#FFF0F3'];

    constructor() {
    }

    ngOnInit() {
        this.chartOptions = {
            series: this.settings.data,
            colors: this.colors,
            chart: {
                width: 380,
                type: "pie"
            },
            labels: this.settings.labels,
            legend: {
                position: 'bottom',
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: this.settings.size.width
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ]
        };
    }
}