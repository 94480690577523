import {AfterViewInit, Component, ChangeDetectorRef, OnInit, ViewChild, ElementRef} from '@angular/core';
import * as shape from 'd3-shape';
import {Edge, Node, ClusterNode, Layout} from '@swimlane/ngx-graph';
import {ReplaySubject, Subject, takeUntil} from 'rxjs';
import {ActivatedRoute} from "@angular/router";
import {TrackingFacade} from "../common/services";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalTrackingComponent} from "../../modal-tracking/modal-tracking.component";

@Component({
    selector: 'app-flow-chart',
    templateUrl: './flow-chart.component.html',
    styleUrls: ['./flow-chart.component.scss']
})
export class FlowChartComponent implements OnInit, AfterViewInit {
    @ViewChild('cardContainer') cardContainer: ElementRef;
    modalRef: NgbModalRef;

    name = 'Workflow Chart';
    clusters: any[] = [];
    nodes: any = [];

    links: any = [];

    layout: string | Layout = 'dagre';

    public layoutSettings = {
        orientation: 'LR',

        edgePadding: 100,
        rankPadding: 200,
        nodePadding: 100,
        compound: true
    };
    public curve: any = shape.curveLinear;

    // line interpolation
    curveType: string = 'Natural';
    interpolationTypes = [
        'Bundle',
        'Cardinal',
        'Catmull Rom',
        'Linear',
        'Monotone X',
        'Monotone Y',
        'Natural',
        'Step',
        'Step After',
        'Step Before'
    ];
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    draggingEnabled: boolean = false;
    panningEnabled: boolean = true;
    zoomEnabled: boolean = true;
    workflowInstance: any;
    steps: any[] = []
    activeFlowchart: boolean = false
    zoomSpeed: number = 0.2;
    zoomLevel: number = 1.1;
    minZoomLevel: number = 0.2;
    maxZoomLevel: number = 5.0;
    panOnZoom: boolean = true;
    autoZoom: boolean = false;
    autoCenter: boolean = false;

    update$: Subject<boolean> = new Subject();
    center$: Subject<boolean> = new Subject();
    zoomToFit$: Subject<boolean> = new Subject();

    constructor(private trackingFacade: TrackingFacade, private activeRoute: ActivatedRoute,   private cdRef: ChangeDetectorRef,private modalService: NgbModal) {

    }

    ngAfterViewInit() {
        this.setInterpolationType(this.curveType);
    }

    ngOnInit() {
        this.activeRoute.params.pipe(
            takeUntil(this.destroyed$)
        ).subscribe((params: any) => {
            // Here we process batch
            this.trackingFacade.get(params.id).pipe(
                takeUntil(this.destroyed$)
            ).subscribe((trackingDetails: any) => {
                this.workflowInstance = trackingDetails;
                this.steps = trackingDetails.steps;
                //this.setFlowChartData();
                this.buildFlowchartData()
            });
        });
    }

    setInterpolationType(curveType: any) {
        this.curveType = curveType;
        if (curveType === 'Bundle') {
            this.curve = shape.curveBundle.beta(1);
        }
        if (curveType === 'Cardinal') {
            this.curve = shape.curveCardinal;
        }
        if (curveType === 'Catmull Rom') {
            this.curve = shape.curveCatmullRom;
        }
        if (curveType === 'Linear') {
            this.curve = shape.curveLinear;
        }
        if (curveType === 'Monotone X') {
            this.curve = shape.curveMonotoneX;
        }
        if (curveType === 'Monotone Y') {
            this.curve = shape.curveMonotoneY;
        }
        if (curveType === 'Natural') {
            this.curve = shape.curveNatural;
        }
        if (curveType === 'Step') {
            this.curve = shape.curveStep;
        }
        if (curveType === 'Step After') {
            this.curve = shape.curveStepAfter;
        }
        if (curveType === 'Step Before') {
            this.curve = shape.curveStepBefore;
        }
    }


    async setFlowChartData() {
        this.nodes = [
            {
                id: '1',
                label: '',
                sub_step: true,
                batch_in: '5510',
                batch_out: '5510-1',
                product_name: 'Insemination-gestation-farrowing',
                color: '#0A3400'
            }, {
                id: '2',
                label: '',
                sub_step: true,
                batch_in: '5511',
                batch_out: '5510-1',
                product_name: 'Weaning',
                color: '#0A3400'

            }, {
                id: '3',
                label: '',
                sub_step: true,
                batch_in: '5512',
                batch_out: '5510-1',
                product_name: 'Fattening',
                color: '#0A3400'
            },
            {
                id: '7',
                label: '',
                sub_step: true,
                batch_in: '5510-1',
                batch_out: '5510-6',
                product_name: 'Slaughter',
                color: '#0A3400'
            }, {
                id: '8',
                label: '',
                sub_step: true,
                batch_in: '5510-2',
                batch_out: '5510-6',
                product_name: 'CuttingPlant',
                color: '#0A3400'
            },
            {
                id: '4',
                label: 'Salting',
                sub_step: false,
                batch_in: '5510-6',
                batch_out: '5510-4',
                product_name: '',
            }, {
                id: '5',
                label: 'Curing',
                sub_step: false,
                batch_in: '5510-4',
                batch_out: '5510-5',
                product_name: '',
            }, {
                id: '6',
                label: 'Packaging',
                sub_step: false,
                batch_in: '5510-5',
                batch_out: '5510-6',
                product_name: '',
            }
        ]
        this.links = [
            {
                id: 'a',
                source: '1',
                target: '4',
                label: 'is parent of'
            }, {
                id: 'b',
                source: '2',
                target: '4',
                label: 'custom label'
            }, {
                id: 'd',
                source: '3',
                target: '4',
                label: 'custom label'
            }, {
                id: 'e',
                source: '4',
                target: '7',
                label: 'first link'
            },
            {
                id: 'e',
                source: '4',
                target: '8',
                label: 'first link'
            },
            {
                id: 'f',
                source: '7',
                target: '5',
                label: 'second link'
            },
            {
                id: 'f',
                source: '8',
                target: '5',
                label: 'second link'
            },
            {
                id: 'f',
                source: '5',
                target: '6',
                label: 'second link'
            }
        ]
        this.clusters = [{
            id: 1,
            label: 'Workflow 1',
            childNodeIds: [1, 2, 3, 4, 5, 6]
        }]
        this.update$.next(true)
        this.center$.next(true)
        this.activeFlowchart = true
    }

    zoomIn() {
        this.autoZoom = false
        this.zoomLevel += this.zoomSpeed
    }

    zoomOut() {
        this.autoZoom = false

        this.zoomLevel -= this.zoomSpeed
    }
    setAutoZoom(){
        this.autoZoom = true
    }
    setAutoCenter(){
        this.center$.next(true)
        // const element = this.cardContainer.nativeElement as HTMLElement;
        // const clonedContent = element.cloneNode(true) ;
        //
        //
        // const modal = this.modalService.open(ModalTrackingComponent, {
        //     ariaLabelledBy: "modal-basic-title",
        // });
        // modal.componentInstance.content = clonedContent;
        // modal.componentInstance.ref = modal;
    }
    buildFlowchartData() {
        let prevStep:any = null
        this.steps.map((step: any,index: any) => {
            if(step.filled){
                if (step.batchIn?.length > 1) {
                    this.buildSubstepData(step.batchIn, step.id, step.batchOut,prevStep)
                }
                this.buildStepData(step,step.batchIn[0],index,prevStep);
                prevStep = step.id
            }
        })

        this.setAutoZoom()
        this.update$.next(true)
        this.center$.next(true)
        this.activeFlowchart = true
        this.cdRef.detectChanges();

    }
    buildStepData(step:any,batchIn:any,index:number,prevStep:any) {
        this.nodes.push({
            id: step.id,
            label: step.title,
            sub_step: false,
            batch_in: step.batchIn[0].name,
            batch_out: step.batchOut[0],
            product_name: '',
            color: '#0A3400'
        })
        if(prevStep&&step.batchIn?.length == 1){
            this.links.push({
                id: `${prevStep}_${step.id}`,
                source: prevStep,
                target: step.id,
                label: `prev step to step`
            })
        }

    }
    buildSubstepData(substeps: Array<object>, stepId: string, stepBatchOut: string,prevStep:any) {
        substeps.map((substep: any,index:any) => {
            this.nodes.push({
                id: stepId + '_' + substep.id,
                label: '',
                sub_step: true,
                batch_in: substep.name,
                batch_out: '',
                product_name: substep.productName,
                color: '#FFAAD0'
            })
            this.links.push({
                id: `${substep.id}`,
                source: stepId + '_' + substep.id,
                target: stepId,
                label: `link node`
            })
            if(prevStep&&index==0){
                this.links.push({
                    id: `${prevStep}_${substep.id}`,
                    source: prevStep,
                    target: stepId + '_' + substep.id,
                    label: `prev step to substep`
                })
            }
        })

    }
}
