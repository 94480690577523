import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webapp-view-component',
  templateUrl: './webapp-view-component.component.html',
  styleUrls: ['./webapp-view-component.component.scss']
})
export class WebappViewComponentComponent implements OnInit {

  webappConfig:any;
  constructor() { }

  ngOnInit(): void {

    const item = localStorage.getItem('webapp-config')
    this.webappConfig = item?JSON.parse(item):{}

    console.log(this.webappConfig)
  }

}
