import { Injectable } from "@angular/core";
import { ImagesDocumentsRequest } from "../requests/imagesDocuments.request";

@Injectable()
export class ImagesDocumentsFacade {
    constructor(private request: ImagesDocumentsRequest) {}

    getImage(id: string) {
        return this.request.getImage(id);
    }

    getDocument(id: string) {
        return this.request.getDocument(id);
    }

}
