export const resolveObjectPath = function(path: string, obj: any) {
    return path.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : null
    }, obj || self)
}

export const dateCommonSettings = function() {
    const today = new Date();
    return {
        minDate: {year: 1930, month: 1, day: 1},
        maxDate: {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()},
    }
}
export const parseDateDMY = function (date: any) {
    var fecha = new Date(date);
    var dia = fecha.getDate();
    var mes = fecha.getMonth() +1; // Los meses en JavaScript se indexan desde 0
    var año = fecha.getFullYear();
    var mesFinal = mes.toString()
    var diaFinal = dia.toString()
    if (mes < 10) {
        mesFinal = "0" + mes;
    }
    if (dia < 10) {
        diaFinal = "0" + dia;
    }
    var fechaFormateada = diaFinal + "/" + mesFinal + "/" + año;
    return fechaFormateada;
}
export const parseDateYMD = function (date: any) {
    var fechaOriginal = date;

    var componentesFecha = fechaOriginal.split("/");
    var dia = componentesFecha[0];
    var mes = componentesFecha[1];
    var año = componentesFecha[2];

    if (dia.length === 1) {
        dia = "0" + dia;
    }
    if (mes.length === 1) {
        mes = "0" + mes;
    }

    var fechaFormateada = año + "-" + mes + "-" + dia;

    return fechaFormateada
}