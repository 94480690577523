import {Injectable} from "@angular/core";
import {ProductsRequest} from "../requests/products.request";
import {ProductModel} from "../models";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {PaginationModel} from "../models/pagination.model";
import {DefaultPaginationSettings} from "../config/default-pagination.settings";

@Injectable()
export class ProductsFacade {
    public pages$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private defaultPic: string = '../../../../assets/media/avatars/generic-product.webp';

    constructor(private request: ProductsRequest) {
    }

    query(pagination: PaginationModel = DefaultPaginationSettings, filters?: any): Observable<any> {
        return this.request.query(pagination, filters).pipe(
            map( (response: any) => {
                return {
                    ...response,
                    content: response.content.map( (product: any) => this._buildPicturePath(product))
                };
            })
        );
    }

    get(productId: string) {
        return this.request.get(productId).pipe(
            map( (product: any) => this._buildPicturePath(product))
        );
    }

    getActives() {
        return this.request.getActives();
    }


    create(product: ProductModel) {
        return this.request.create(product);
    }

    update(product: ProductModel) {
        return this.request.update(product);
    }

    delete(productId: string | number) {
        return this.request.delete(productId);
    }

    uploadPic(image: any, productId: number, companyId: number | string) {
        return this.request.uploadPic(image, productId, companyId);
    }

    private _buildPicturePath(product: any) {
        const imagePath: string = !!product?.image?.path ? `/images/${product.company.id}/${product.image.path}` : this.defaultPic;
        let companyImagePath: string = ''
        let qrPath: string = ''
        if(product?.company?.logo?.path){
            const companyLogo = product?.company?.logo?.path
            companyImagePath = `/images/${product.company.id}/${companyLogo}`;
        }
        const productQr = product?.productQr?.name
        qrPath = `/images/${product.company.id}/${productQr}`;

        return {
            ...product,
            badgeType: product?.approval?.status.toLowerCase(),
            imagePath,
            companyImagePath,
            qrPath
        }
    }

    getQRDynamic(ean: string, batchId: string, productId: string) {

        return this.request.getImageDynamic(ean, batchId, productId);
    }
}
