import {Injectable} from "@angular/core";
import {WorkflowRequest} from "../requests/workflows.request";
import {WorkflowModel} from "../models";
import {map} from "rxjs/operators";
import {WorkflowDataTypes, WorkflowStepDataModel} from "../models/workflow-step.model";
import {PaginationModel} from "../models/pagination.model";
import {environment} from "../../../../environments/environment";

@Injectable()
export class WorkflowsFacade {
    private defaultPagination: PaginationModel = {
        last: false,
        pageNumber: 0,
        pageSize: environment.pagination.pageSize,
        totalPages: 0,
        totalElements: 0,
    }
    private defaultPic: string = '../../../../assets/media/avatars/company-generic.png';

    constructor(private request: WorkflowRequest) {
    }

    query(pagination: PaginationModel = this.defaultPagination, filters?: any) {
        return this.request.query(pagination, filters).pipe(
            map( (result: any) => {

                return {
                    ...result,
                    content: result.content.map( (workflow: any) => this._parseResponse(workflow))
                }
            })
        );
    }

    get(workflowId: string) {
        return this.request.get(workflowId).pipe(
            map( (workflow: any) => this._parseResponse(workflow))
        );
    }

    create(workflow: WorkflowModel) {
        return this.request.create(workflow);
    }

    update(workflow: WorkflowModel) {
        return this.request.update(workflow);
    }

    delete(workflowId: string | number) {
        return this.request.delete(workflowId);
    }
    duplicate(workflowId: string | number) {
        return this.request.duplicate(workflowId);
    }
    private _parseResponse(workflow: WorkflowModel) {
        const steps = workflow.workflowSteps
            .sort((a, b) => a.stepOrder - b.stepOrder)
            .map( (step: any) => {
            return {
                ...step,
                visibility:this.parseVisibility(step),
                children: step.workflowStepDataFields.map( (field: WorkflowStepDataModel) => {
                    return {
                        ...field,
                        visibility:this.parseVisibility(field),
                        fieldType: WorkflowDataTypes[field.workflowDataType]
                    }
                }).sort( (a: any, b: any) => a.fieldOrder < b.fieldOrder)
            }
        });
        let companyImagePath: string = this.defaultPic
        if(workflow?.company?.logo?.path){
            const companyLogo = workflow?.company?.logo?.path
            companyImagePath = `/images/${workflow.company.id}/${companyLogo}`;
        }
        return {
            ...workflow,
            workflowSteps: steps,
            children: steps,
            status: workflow.enabled ? 'Active' : 'Inactive',
            badgeType: workflow.enabled ? 'active' : 'inactive',
            companyImagePath
        }
    }
    parseVisibility(item:any){
        if(item._public){
            return 'public'
        }
        if(item._private){
            return 'private'
        }
        if(item.viewer){
            return 'viewer'
        }
    }
    hasActivityPending(workflowId: string | number) {
        return this.request.hasActivityPending(workflowId);
    }
}
