import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './modules/auth/services/auth.service';
import {environment} from 'src/environments/environment';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { FormsModule } from '@angular/forms';

// #fake-start#
import {FakeAPIService} from './_fake/fake-api.service';
import {CommonStelvioModule} from "./modules/common/common.module";
import {DashboardModule} from "./modules/dashboard/dashboard.module";
import {NgxPermissionsModule, NgxPermissionsService} from "ngx-permissions";
import {BbtwinsPermissionsService} from "./services/bbtwins-permissions.service";
import {AuthInterceptor} from "./modules/auth/services/auth.interceptor";
import {GoogleMapsModule} from "@angular/google-maps";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import { FlowChartComponent } from './modules/flow-chart/flow-chart.component';
import { ModalTrackingComponent } from './modal-tracking/modal-tracking.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HomeComponent } from './modules/home/home.component';

function appInitializer(authService: AuthService) {
    return () => {
        return new Promise((resolve) => {
            //@ts-ignore
            authService.getUserByToken().subscribe().add(resolve);
        });
    };
}

@NgModule({
    declarations: [AppComponent, FlowChartComponent, ModalTrackingComponent, HomeComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        HttpClientModule,
        ClipboardModule,
        FormsModule,
        // #fake-start#
        environment.isMockEnabled
            ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
                passThruUnknownUrl: true,
                dataEncapsulation: false,
            })
            : [],
        // #fake-end#
        AppRoutingModule,
        InlineSVGModule.forRoot(),
        NgbModule,
        CommonStelvioModule,
        DashboardModule,
        NgxPermissionsModule.forRoot(),
        GoogleMapsModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxGraphModule,
        NgApexchartsModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        BbtwinsPermissionsService,
        {
            provide: APP_INITIALIZER,
            useFactory: (ps: NgxPermissionsService, ds: BbtwinsPermissionsService) => function () {
                return ds.load().subscribe((data) => {
                    return ps.loadPermissions(data)
                })
            },
            deps: [NgxPermissionsService, BbtwinsPermissionsService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
